var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"step-title"},[_c('span',[_c('span',[_c('span',[_vm._v(_vm._s(_vm.$t("editTrip.experimentalStep.title")))])])])]),_c('div',{staticClass:"experimentalSectionsContainer list",attrs:{"id":"experimentalSectionsContainer"}},_vm._l((_vm.subscription.experimentalSections),function(sectionId,index){return _c('div',{key:index},[_c('div',{class:'section section-' + index},[_c('h4',{staticClass:"level1"},[_vm._v(" "+_vm._s(sectionId.title)+" ")]),(_vm.isProtoEnabled)?_c('b-container',[_c('b-row',[_c('b-col',[_c('RadioInput',{attrs:{"options":[
                  { value: 1, text: 'R1', description: 'Casier régulier 1' },
                  { value: 2, text: 'M1', description: 'Casier modifié 1' },
                  { value: 3, text: 'R2', description: 'Casier régulier 2' },
                  { value: 4, text: 'M2', description: 'Casier modifié 2' }
                ],"label":"Type de casier","required":""},model:{value:(_vm.form.trapType),callback:function ($$v) {_vm.$set(_vm.form, "trapType", $$v)},expression:"form.trapType"}}),_c('p',[_vm._v("form.trapType : "+_vm._s(_vm.form.trapType))])],1),_c('b-col',[_c('RadioInput',{attrs:{"options":[
                  { value: 1, text: 'M', description: 'Mâle' },
                  { value: 2, text: 'F', description: 'Femelle' },
                  { value: 3, text: 'FO', description: 'Femelle œuvée' }
                ],"label":"Sexe du homard","required":""},model:{value:(_vm.form.sex),callback:function ($$v) {_vm.$set(_vm.form, "sex", $$v)},expression:"form.sex"}}),_c('p',[_vm._v("form.sex : "+_vm._s(_vm.form.sex))])],1),_c('b-col',[_c('IntegerInput',{attrs:{"min":1,"max":14,"label":"Taille","placeholder":"De 1 à 14","required":""},model:{value:(_vm.form.size),callback:function ($$v) {_vm.$set(_vm.form, "size", $$v)},expression:"form.size"}}),_c('p',[_vm._v("form.size : "+_vm._s(_vm.form.size))])],1)],1)],1):_vm._e(),_vm._l((sectionId.groups),function(group,index2){return _c('div',{key:index2},[_c('div',{class:'group group-' + index2 + ' nb-gear-' + _vm.gearNumber},[_vm._l((group.gears),function(gear,index3){return _c('div',{key:index3},[_c('div',{class:'gear gear-' +
                    index3 +
                    ' nb-column-' +
                    _vm.allowedCatchTypesNumber},[_c('h5',[_vm._v(" "+_vm._s(gear.type.description)+" ")]),_c('div',{class:'row column-' + _vm.allowedCatchTypesNumber + ' title'},_vm._l((gear.allowedCatchTypes),function(catchTypeId,index4){return _c('div',{key:index4},[_c('h6',[_vm._v(" "+_vm._s(catchTypeId.abbrv)+" ")])])}),0),_vm._l((_vm.NbRowForAnExperimentalGear[index3]),function(numberForExGear,index5){return _c('div',{key:index5},[_c('div',{class:'row nb-column-' + _vm.allowedCatchTypesNumber},_vm._l((gear.allowedCatchTypes),function(catchTypeId,index6){return _c('div',{key:index6},[_c('ExperimentalInput',{attrs:{"id":'experimentalCatch-' +
                            index3 +
                            '-' +
                            index6 +
                            '-' +
                            (index5 + 1),"min":0,"max":catchTypeId.value_max,"digits":true,"refeshError":_vm.showErrorData,"errorMsg":'MAX ' + catchTypeId.value_max,"type":"text"},on:{"error":_vm.addErrors,"input":function($event){return _vm.handleInput(index3, index5)}},model:{value:(
                          _vm.form.experimentalData[index3][index5 + 1][index6]
                        ),callback:function ($$v) {_vm.$set(_vm.form.experimentalData[index3][index5 + 1], index6, $$v)},expression:"\n                          form.experimentalData[index3][index5 + 1][index6]\n                        "}})],1)}),0)])})],2)])}),_c('p',{staticClass:"clearBother"})],2)])})],2)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }