<template>
  <div v-if="hasOneMainField" class="catch" name="CatchViewer">
    <h4 v-if="title" class="leftAlign">
      {{ title }}
    </h4>
    <b-row>
      <b-col lg="4" cols="6" v-if="capture.specieOtherDescription">
        <LabelView
          :label="$t('catch.specieDesc')"
          :value="capture.specieOtherDescription"
          type="text"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.tagNumber">
        <LabelView
          :label="$t('catch.tagNumber')"
          :value="capture['tagNumber']"
          type="text"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture?.position?.latitude">
        <LabelView :label="$t('latitude')" :value="latitude" type="text" />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture?.position?.longitude">
        <LabelView :label="$t('longitude')" :value="longitude" type="text" />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.caughtWeight != null">
        <UnitConverter
          :label="$t('catch.caughtWeight')"
          :value="capture.caughtWeight"
          unit="weight"
          viewOnly
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.keptWeight != null">
        <UnitConverter
          :label="$t('catch.keptWeight')"
          :value="capture['keptWeight']"
          unit="weight"
          viewOnly
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.keptNbSpecimen != null">
        <LabelView
          :label="$t('catch.keptNbSpecimen')"
          :value="capture['keptNbSpecimen']"
          type="text"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="averageKeptWeight != null">
        <LabelView
          :label="$t('catch.keptAvg')"
          :value="averageKeptWeight"
          type="text"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.discWeight != null">
        <UnitConverter
          :label="$t('catch.discWeight')"
          :value="capture['discWeight']"
          unit="weight"
          viewOnly
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.discNbSpecimen != null">
        <LabelView
          :label="$t('catch.discNbSpecimen')"
          :value="capture['discNbSpecimen']"
          type="text"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="averageDiscWeight != null">
        <LabelView
          :label="$t('catch.discAvg')"
          :value="averageDiscWeight"
          type="text"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.specimenCaught != null">
        <LabelView
          :label="$t('catch.specimenCaught')"
          :value="capture['specimenCaught']"
          type="text"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.specimenDiscInfected != null">
        <LabelView
          :label="$t('catch.specimenDiscInfected')"
          :value="capture['specimenDiscInfected']"
          type="text"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.specimenHook != null">
        <LabelView
          :label="$t('catch.specimenHook')"
          :value="capture['specimenHook']"
          type="text"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.specimenEntangled != null">
        <LabelView
          :label="$t('catch.specimenEntangled')"
          :value="capture['specimenEntangled']"
          type="text"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.keptWeight2 != null">
        <UnitConverter
          :label="$t('catch.keptWeight2')"
          :value="capture['keptWeight2']"
          unit="weight"
          viewOnly
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.berried != null">
        <LabelView
          :label="$t('catch.berried')"
          :value="capture['berried']"
          type="text"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.flankLength != null">
        <UnitConverter
          :label="$t('catch.flankLength')"
          :value="capture['flankLength']"
          unit="cm"
          viewOnly
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.dressedLength != null">
        <UnitConverter
          :label="$t('catch.dressedLength')"
          :value="capture['dressedLength']"
          unit="cm"
          viewOnly
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.specimenCondition">
        <LabelView
          :label="$t('catch.specimenCondition')"
          :value="capture['specimenCondition']"
          :options="conditions"
          type="select"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.marketableIndicator">
        <LabelView
          :label="$t('catch.marketableIndicator')"
          :value="capture['marketableIndicator']"
          :options="responses"
          type="select"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.legalityIndicator">
        <LabelView
          :label="$t('catch.legalityIndicator')"
          :value="capture['legalityIndicator']"
          :options="responses"
          type="select"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.encounterType">
        <LabelView
          :label="$t('catch.encounterType')"
          :value="capture['encounterType']"
          :options="encounterTypes"
          type="select"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.productSize">
        <LabelView
          :label="$t('catch.size')"
          :value="capture['productSize']"
          :options="productSizes"
          type="select"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.marketDescription">
        <LabelView
          :label="$t('catch.marketDescription')"
          :value="capture['marketDescription']"
          type="text"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.babyDiscWt != null">
        <UnitConverter
          :label="$t('catch.babyDiscWt')"
          :value="capture['babyDiscWt']"
          unit="weight"
          viewOnly
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.roeStageDescription">
        <LabelView
          :label="$t('catch.roeStageDescription')"
          :value="capture['roeStageDescription']"
          type="text"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.hasRoes">
        <LabelView
          :label="$t('catch.roeIndicator')"
          :value="capture['hasRoes']"
          :options="responses"
          type="select"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.sampleIndicator">
        <LabelView
          :label="$t('catch.sampleIndicator')"
          :value="capture['sampleIndicator']"
          :options="responses"
          type="select"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.femalePercentage != null">
        <LabelView
          :label="$t('catch.femalePercentage')"
          :value="capture['femalePercentage']"
          type="text"
        />
      </b-col>
      <b-col lg="4" cols="6" v-if="capture.remark">
        <LabelView
          :label="$t('editTrip.remarkTitle')"
          :value="capture['remark']"
          type="text"
        />
      </b-col>
    </b-row>
    <div v-if="hasDetails">
      <div
        v-for="(detail, detailIndex) in capture['details']"
        :key="'catchdetail-' + detailIndex"
      >
        <b-row>
          <b-col v-if="detail['size']" lg="4" cols="6">
            <LabelView
              :label="$t('catch.size')"
              :value="detail['size']"
              :options="productSizes"
              type="select"
            />
          </b-col>
          <b-col v-if="detail['weight'] != null" lg="4" cols="6">
            <LabelView
              :label="$t('editTrip.lastStep.weight')"
              :value="detail['weight']"
              type="text"
            />
          </b-col>
          <b-col v-if="detail['remark']" lg="4" cols="6">
            <LabelView
              :label="$t('editTrip.remarkTitle')"
              :value="detail['remark']"
              type="text"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import LabelView from "@/components/LabelView.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import Geolocation from "@/utils/geolocation.js";

export default {
  name: "CatchViewer",
  components: {
    LabelView,
    UnitConverter
  },
  props: {
    capture: Object
  },
  computed: {
    ...mapState({
      currentTrip: state => state.selectedTrip,
      form: state => state.selectedTrip.form,
      subform: state => state.selectedTrip.subform,
      species: state => state.systemsLists.species,
      productForms: state => state.systemsLists.productForm,
      productSizes: state => state.systemsLists.productSize,
      conditions: state => state.systemsLists.sarCondition,
      responses: state => state.systemsLists.response,
      encounterTypes: state => state.systemsLists.encounterType
    }),
    ...mapGetters(["getPropertyValue"]),
    hasAverageSpecimenWeight() {
      return this.getPropertyValue("hasAverageSpecimenWeight", {
        forms: this.form,
        subforms: this.subform,
        species: this.capture.species
      });
    },
    hasOneMainField() {
      // General rule
      // In each occurrence of node CATCH, at least one quantity must be declared.
      // So, at least one of these elements must contain a value greater than or equal to 0 in order to display current catch.
      return (
        this.capture.keptWeight != null ||
        this.capture.keptNbSpecimen != null ||
        this.capture.discNbSpecimen != null ||
        this.capture.specimenCaught != null ||
        this.capture.discWeight != null
      );
    },
    averageKeptWeight() {
      if (this.hasAverageSpecimenWeight) {
        const weight = this.capture.keptWeight;
        const quantity = this.capture.keptNbSpecimen;
        if (weight && quantity) {
          const value = weight / quantity;
          const pow = Math.pow(10.0, 4);
          return Math.round(value * pow) / pow + " kg";
        }
      }
      return null;
    },
    averageDiscWeight() {
      if (this.hasAverageSpecimenWeight) {
        const weight = this.capture.discWeight;
        const quantity = this.capture.discNbSpecimen;
        if (weight && quantity) {
          const value = weight / quantity;
          const pow = Math.pow(10.0, 4);
          return Math.round(value * pow) / pow + " kg";
        }
      }
      return null;
    },
    hasDetails() {
      return this.capture.details?.length > 0;
    },
    title() {
      const formId = this.capture.productForm;
      const specie = this.species.find(x => x.value == this.capture.species);
      let result;

      if (specie) {
        result = specie.text;
        if (formId) {
          const form = this.productForms.find(x => x.value == formId);
          if (form) {
            result = `${result} (${form.text})`;
          }
        }
      }

      return result;
    },
    latitude() {
      const deg = this.capture.position.latitude;
      const dms = Geolocation.convertDD2toDMS(deg, "lat").toString;
      return `${dms} = ${deg} °`;
    },
    longitude() {
      const deg = this.capture.position.longitude;
      const dms = Geolocation.convertDD2toDMS(deg, "lon").toString;
      return `${dms} = ${deg} °`;
    }
  }
};
</script>
