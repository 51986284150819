<template>
  <div :name="$options.name" class="experimentalSectionsContainer list">
    <h4 class="level1 nextIsTitle">
      {{ $t("editTrip.experimentalStep.title") }}
    </h4>
    <h4 class="level2">{{ $t("editTrip.experimentalStep.subtitle") }}</h4>
    <b-container>
      <b-row>
        <b-col
          v-for="(table, tableIndex) in experimentalDataTables"
          :key="'experimentalDataTable-' + tableIndex"
          fluid
          cols="6"
        >
          <h5>{{ $t("editTrip.experimentalStep.trap") }} {{ table.trapId }}</h5>
          <table>
            <tr>
              <th>{{ $t("editTrip.experimentalStep.male") }}</th>
              <th>{{ $t("editTrip.experimentalStep.female") }}</th>
              <th>{{ $t("editTrip.experimentalStep.berried") }}</th>
            </tr>
            <tr>
              <td>{{ table.male.map(s => s.size).join(", ") }}</td>
              <td>{{ table.female.map(s => s.size).join(", ") }}</td>
              <td>{{ table.berried.map(s => s.size).join(", ") }}</td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-container>
    <br />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ExperimentalDataViewer",
  computed: {
    ...mapState({
      trip: state => state.selectedTrip
    }),
    lobsterPopulationSamples() {
      if (!("efforts" in this.trip)) return [];
      if (this.trip.efforts.length === 0) return [];
      if (!("tows" in this.trip.efforts[0])) return [];
      if (this.trip.efforts[0].tows.length === 0) return [];
      return this.trip.efforts[0].tows[0].lobsterPopulationSamples ?? [];
    },
    experimentalDataTables() {
      return [
        this.table("R", 1),
        this.table("M", 1),
        this.table("R", 2),
        this.table("M", 2)
      ];
    }
  },
  methods: {
    table(type, number) {
      return {
        trapId: type + number,
        male: this.lobsterPopulationSamples.filter(sample => {
          return (
            sample.trapType === type &&
            sample.trapNumber === number &&
            sample.sex === "M"
          );
        }),
        female: this.lobsterPopulationSamples.filter(sample => {
          return (
            sample.trapType === type &&
            sample.trapNumber === number &&
            sample.sex === "F"
          );
        }),
        berried: this.lobsterPopulationSamples.filter(sample => {
          return (
            sample.trapType === type &&
            sample.trapNumber === number &&
            ["BF", "FO"].includes(sample.sex)
          );
        })
      };
    }
  }
};
</script>

<style scoped lang="scss">
h5 {
  width: 100%;
  text-align: center;
  font-weight: bold;
}
table {
  border: 1px solid $lightColor;
  padding: 4px;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
th {
  border: 1px solid $lightColor;
  background-color: $lightColor;
  width: 33%;
}
td {
  border: 1px solid $lightColor;
  background-color: $mediumColor;
  width: 33%;
}
.col-6 {
  margin-bottom: 1rem;
}
</style>
