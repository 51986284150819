<template>
  <fieldset
    v-if="mminteration['hasMmi'] === $const.YES"
    :disabled="mminteration['closeDatetime'] !== undefined"
  >
    <GeopositionWidget
      :position="mminteration['occursAt']"
      @binding="
        value => {
          mminteration['occursAt'] = {
            ...mminteration['occursAt'],
            ...value
          };
        }
      "
      :required="true"
      :minLat="[38]"
      :maxLat="[72]"
      :minLong="[-148]"
      :maxLong="[-40]"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'sarGPS-' + index"
    />
    <br />
    <GenericInput
      :label="$t('editTrip.speciesAtRiskStep.location')"
      v-model="mminteration['location']"
      type="text"
      :maxlength="150"
      :required="true"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'location-' + index"
    />
    <Select
      :label="$t('editTrip.speciesAtRiskStep.gearType')"
      :options="systemsLists.lostGearTypes"
      v-model="mminteration['gearType']"
      :required="true"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'gearType-' + index"
    />
    <RadioInput
      v-model="mminteration['isDamaged']"
      :label="$t('editTrip.speciesAtRiskStep.isDamaged')"
      :options="systemsLists.response"
      :refeshError="parentShowError"
      :required="true"
      @error="addErrorToParent"
    />
    <RadioInput
      v-model="mminteration['isLost']"
      :label="$t('editTrip.speciesAtRiskStep.isLost')"
      :options="systemsLists.response"
      :refeshError="parentShowError"
      :required="true"
      @error="addErrorToParent"
    />
    <RadioInput
      v-model="mminteration['isKnown']"
      :label="$t('editTrip.speciesAtRiskStep.isKnown')"
      :options="systemsLists.response"
      :refeshError="parentShowError"
      :required="true"
      @error="addErrorToParent"
    />
    <GenericInput
      :label="$t('editTrip.speciesAtRiskStep.damageRemark')"
      v-model="mminteration['damageRemark']"
      type="text"
      :maxlength="150"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'damageRemark-' + index"
    />
    <br />
    <GenericInput
      :label="$t('editTrip.speciesAtRiskStep.specimenRemark')"
      v-model="mminteration['specimenRemark']"
      type="text"
      :maxlength="150"
      :required="true"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'specimenRemark-' + index"
    />
    <Select
      :label="$t('editTrip.speciesAtRiskStep.condition')"
      :options="systemsLists.mmConditions"
      v-model="mminteration['condition']"
      :required="true"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'sarCondition-' + index"
    />
    <Select
      :label="$t('editTrip.speciesAtRiskStep.confidence')"
      :options="systemsLists.confidenceLevels"
      v-model="mminteration['confidence']"
      :required="true"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'sarConfidence-' + index"
    />
    <br />
    <IntegerInput
      :label="$t('editTrip.speciesAtRiskStep.estimate')"
      v-model="mminteration['estimate']"
      :min="1"
      :max="9999"
      :required="true"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'estimate-' + index"
    />
    <br />
    <IntegerInput
      :label="$t('editTrip.speciesAtRiskStep.min')"
      v-model="mminteration['min']"
      :min="1"
      :max="mminteration['estimate'] ? mminteration['estimate'] : 9999"
      :required="true"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'min-' + index + mminteration['estimate']"
    />
    <IntegerInput
      :label="$t('editTrip.speciesAtRiskStep.max')"
      v-model="mminteration['max']"
      :min="mminteration['estimate'] ? mminteration['estimate'] : 1"
      :max="9999"
      :required="true"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'max-' + index + mminteration['estimate']"
    />
    <RadioInput
      v-model="mminteration['hasVideo']"
      :key="'hasVideo-' + index"
      :label="$t('editTrip.speciesAtRiskStep.hasVideo')"
      :options="systemsLists.response"
      :refeshError="parentShowError"
      :required="true"
      @error="addErrorToParent"
    />
    <RadioInput
      v-model="mminteration['hasPhoto']"
      :key="'hasPhoto-' + index"
      :label="$t('editTrip.speciesAtRiskStep.hasPhoto')"
      :options="systemsLists.response"
      :refeshError="parentShowError"
      :required="true"
      @error="addErrorToParent"
    />
    <RadioInput
      v-model="mminteration['hasSample']"
      :key="'hasSample-' + index"
      :label="$t('editTrip.speciesAtRiskStep.hasSample')"
      :options="systemsLists.response"
      :refeshError="parentShowError"
      :required="true"
      @error="addErrorToParent"
    />
    <RadioInput
      v-model="mminteration['hasDoc']"
      :key="'hasDoc-' + index"
      :label="$t('editTrip.speciesAtRiskStep.hasDoc')"
      :options="systemsLists.response"
      :refeshError="parentShowError"
      :required="true"
      @error="addErrorToParent"
    />
    <GenericInput
      v-if="mminteration['hasDoc'] === $const.YES"
      :label="$t('editTrip.speciesAtRiskStep.docRemark')"
      v-model="mminteration['docRemark']"
      type="text"
      :maxlength="150"
      :required="true"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'docRemark-' + index"
    />
    <Select
      :label="$t('editTrip.speciesAtRiskStep.category')"
      :options="systemsLists.mmLengths"
      v-model="mminteration['category']"
      :required="true"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'category-' + index"
    />
    <UnitConverter
      v-if="mminteration['category'] === $const.BODY_LEN_CAT_OTHER"
      v-model="mminteration['length']"
      :label="$t('editTrip.speciesAtRiskStep.length')"
      :min="0"
      :max="99999.999"
      :refresh="parentShowError"
      :key="'length-' + index"
      unit="m"
      required
      @error="addErrorToParent"
    />
    <GenericInput
      :label="$t('editTrip.speciesAtRiskStep.description')"
      v-model="mminteration['description']"
      type="text"
      :maxlength="150"
      :required="true"
      @error="addErrorToParent"
      :refeshError="parentShowError"
      :key="'description-' + index"
    />
  </fieldset>
</template>

<script>
import { mapState } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import Select from "@/components/Select.vue";
import GeopositionWidget from "@/components/widgets/GeopositionWidget.vue";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import RadioInput from "@/components/widgets/RadioInput.vue";

export default {
  components: {
    GenericInput,
    Select,
    GeopositionWidget,
    IntegerInput,
    RadioInput,
    UnitConverter
  },
  props: {
    addErrorToParent: Function,
    parentShowError: Number,
    mminteration: Object,
    index: Number
  },
  data() {
    return {
      name: "details"
    };
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists,
      subscription: state => state.currentSubscription,
      isFinalReview: state => state.editTripSubform.isFinalReview
    })
  },
  watch: {
    isFinalReview: {
      deep: true,
      handler: function() {
        if (this.isFinalReview) {
          if (this.mminteration.hasDoc !== this.$const.YES) {
            this.mminteration.docRemark = null;
          }
          if (this.mminteration.category !== this.$const.BODY_LEN_CAT_OTHER) {
            this.mminteration.length = null;
          }
        }
      }
    }
  }
};
</script>
