<template>
  <fieldset name="BycatchEdit">
    <span
      v-if="!isSpecieOther"
      v-b-modal="uid"
      class="add-remark-btn-notTitle"
    ></span>
    <Popup
      v-model="catchSpecie['remark']"
      :title-label="$t('editTrip.remarkTitle')"
      :maxlength="2000"
      :modalName="uid"
    />
    <Select
      :label="$t('editTrip.effortStep.specie')"
      v-model="selectedSpecie"
      :options="availableSpecies"
      :required="true"
      @error="addError"
      :refeshError="showErrorData"
    />
    <br />
    <UnitConverter
      v-if="option?.hasCatchKeptWeight"
      :label="$t('catch.keptWeight')"
      v-model="catchSpecie['keptWeight']"
      :required="option.hasCatchKeptWeight === $const.MANDATORY"
      :min="0"
      :max="999999.999"
      unit="weight"
      @error="addError"
      :refresh="showErrorData"
    />

    <IntegerInput
      v-if="option?.hasCatchSpecimenKept"
      :label="$t('catch.keptNbSpecimen')"
      v-model="catchSpecie['keptNbSpecimen']"
      :required="option.hasCatchSpecimenKept === $const.MANDATORY"
      :min="0"
      :max="9999"
      @error="addError"
      :refeshError="showErrorData"
    />
    <UnitConverter
      v-if="option?.hasDiscWeight"
      :label="$t('catch.discWeight')"
      v-model="catchSpecie['discWeight']"
      :required="option.hasDiscWeight === $const.MANDATORY"
      :min="0"
      :max="999999.999"
      unit="weight"
      @error="addError"
      :refresh="showErrorData"
    />
    <IntegerInput
      v-if="option?.hasCatchSpecimenDisc"
      :required="option.hasCatchSpecimenDisc === $const.MANDATORY"
      :label="$t('catch.discNbSpecimen')"
      v-model="catchSpecie['discNbSpecimen']"
      :min="0"
      :max="9999"
      @error="addError"
      :refeshError="showErrorData"
    />
    <GenericInput
      v-if="isSpecieOther"
      :label="$t('editTrip.remark')"
      v-model="catchSpecie['remark']"
      type="text"
      :maxlength="2000"
      @error="addError"
      :refeshError="showErrorData"
      :required="true"
    />
  </fieldset>
</template>
<script>
import GenericInput from "@/components/GenericInput.vue";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import Select from "@/components/Select.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import Popup from "@/components/widgets/Popup.vue";

let bycatchEditCounter = 0;

export default {
  name: "BycatchEdit",
  components: {
    IntegerInput,
    GenericInput,
    Select,
    Popup,
    UnitConverter
  },
  props: {
    showErrorData: Number, // serve as trigger to tell the fields to add an error if the value is invalid
    addError: Function,
    effort: Object,
    options: Object,
    initialCatch: Object,
    species: Array,
    exclude: Array, // catch species used in the same tow
    catchIndex: Number
  },
  data() {
    return {
      uid: null,
      option: null,
      selectedSpecie: null,
      catchSpecie: {
        species: null,
        remark: null,
        keptNbSpecimen: null,
        keptWeight: null,
        discNbSpecimen: null,
        discWeight: null
      },
      refreshKey: 0
    };
  },
  computed: {
    availableSpecies() {
      const vm = this;
      const filtered = this.species.filter(s => {
        if (s.value == null) return true;
        if (s.value == this.selectedSpecie) return true;
        return !vm.exclude.includes(s.value);
      });
      return filtered;
    },
    isSpecieOther() {
      return this.catchSpecie.species === this.$const.SPECIE_OTHER;
    }
  },
  watch: {
    selectedSpecie: function() {
      if (process.env.VUE_APP_DEBUG_CATCH) {
        // eslint-disable-next-line no-console
        console.debug("BycatchEdit: selected specie", this.selectedSpecie);
      }
      this.catchSpecie.species = this.selectedSpecie;
      if (this.catchSpecie.species) {
        this.option = this.options[this.catchSpecie.species];
        if (this.option.hasSpecieFrmId === this.$const.MANDATORY) {
          this.catchSpecie.productForm = this.$const.FORM_ROUND;
        }
        this.$emit("specie", this.selectedSpecie);
      } else {
        this.option = null;
      }
    },
    catchSpecie: {
      handler: function() {
        if (process.env.VUE_APP_DEBUG_CATCH) {
          // eslint-disable-next-line no-console
          console.debug("BycatchEdit: form watch");
        }
        // modify a copy so we dont trigger a second watch everytime
        const data = Object.assign({}, this.catchSpecie);
        if (
          this.option &&
          this.option.hasSpecieFrmId === this.$const.MANDATORY
        ) {
          data.productForm = this.$const.FORM_ROUND;
        }

        this.$emit("update", data);
      },
      deep: true
    }
  },
  methods: {},
  beforeMount() {
    this.uid = "bycatchedit-" + bycatchEditCounter;
    bycatchEditCounter += 1;

    Object.assign(this.catchSpecie, this.initialCatch);

    if (this.catchSpecie.species) {
      this.selectedSpecie = this.catchSpecie.species;
    }

    if (process.env.VUE_APP_DEBUG_CATCH) {
      // eslint-disable-next-line no-console
      console.debug("BycatchEdit: mount", this.catchSpecie, this.option);
    }
  },
  destroyed() {
    if (process.env.VUE_APP_DEBUG_CATCH) {
      // eslint-disable-next-line no-console
      console.debug("BycatchEdit: specie not used", -this.selectedSpecie);
    }
    this.$emit("specie", -this.selectedSpecie);
  }
};
</script>
