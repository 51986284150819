<template>
  <div
    v-if="currentTrip.gearLossEvents && currentTrip.gearLossEvents.length > 0"
    name="gearLostViewer"
  >
    <div v-if="isOldGearLost">
      <GearLostOldViewer
        v-for="(gearLoss, index) in currentTrip.gearLossEvents"
        :key="'gearloss-' + index"
        :gearLoss="gearLoss"
        :index="index"
        :gearTypes="systemsLists.gearType"
        class="towEntriesContainer"
      />
    </div>
    <div v-if="!isOldGearLost">
      <div
        v-for="(gearLoss, index) in currentTrip.gearLossEvents"
        :key="'gearloss-' + index"
        class="towEntriesContainer"
      >
        <div>
          <fieldset class="fieldsetViewTrip">
            <h4 class="level1 viewStep">
              {{ $t("editTrip.gearLossStep.loss") }}&nbsp;{{ index + 1 }}
            </h4>
            <b-row>
              <b-col lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.gearLossStep.repDate')"
                  :value="$showDateOnly(gearLoss['creation'])"
                  type="text"
                />
              </b-col>
              <b-col v-if="gearLoss['status']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.gearLossStep.status')"
                  :value="gearLoss['status']"
                  :options="systemsLists.lostGearStatuses"
                  type="select"
                />
              </b-col>
              <b-col v-if="gearLoss['target']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.effortStep.target')"
                  :value="gearLoss['target']"
                  :options="systemsLists.species"
                  type="select"
                />
              </b-col>
              <b-col
                v-if="gearLoss['fishingLicenceNumber']"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('editTrip.effortStep.licenceNumber')"
                  :value="gearLoss['fishingLicenceNumber']"
                  type="text"
                />
              </b-col>
              <b-col v-if="gearLoss['area']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('fields.area')"
                  :value="gearLoss['area']"
                  :options="systemsLists.areas"
                  type="select"
                />
              </b-col>
              <b-col
                v-if="gearLoss['occursAt'] && gearLoss['occursAt']['datetime']"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('editTrip.gearLossStep.lostDate')"
                  :value="$showDateOnly(gearLoss['occursAt']['datetime'])"
                  type="text"
                />
              </b-col>
              <b-col v-if="gearLoss['recovery']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.gearLossStep.recoveryDate')"
                  :value="$showDateOnly(gearLoss['recovery'])"
                  type="text"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                v-if="gearLoss.occursAt && gearLoss.occursAt['latitude']"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('latitude')"
                  :value="
                    convertDD2toDMS(gearLoss.occursAt['latitude'], 'lat') +
                      ' = ' +
                      gearLoss.occursAt['latitude'] +
                      '°'
                  "
                  type="text"
                />
              </b-col>
              <b-col
                v-if="gearLoss.occursAt && gearLoss.occursAt['longitude']"
                lg="4"
                sm="6"
                cols="12"
              >
                <LabelView
                  :label="$t('longitude')"
                  :value="
                    convertDD2toDMS(gearLoss.occursAt['longitude'], 'lon') +
                      ' = ' +
                      gearLoss.occursAt['longitude'] +
                      '°'
                  "
                  type="text"
                />
              </b-col>
              <b-col
                lg="4"
                sm="6"
                cols="12"
                v-if="gearLoss.occursAt && gearLoss.occursAt['nafo']"
              >
                <LabelView
                  :label="$t('nafo')"
                  :value="gearLoss.occursAt['nafo']"
                  type="text"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="gearLoss['gearType']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.gearLossStep.gearType')"
                  :value="gearLoss['gearType']"
                  :options="systemsLists.lostGearTypes"
                  type="select"
                />
              </b-col>
              <b-col v-if="gearLoss['gearDesc']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.gearLossStep.gearDesc')"
                  :value="gearLoss['gearDesc']"
                  type="text"
                />
              </b-col>
              <b-col v-if="gearLoss['nbGear']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.gearLossStep.nbGear')"
                  :value="gearLoss['nbGear']"
                  type="text"
                />
              </b-col>
              <b-col v-if="gearLoss['ropeLength']" lg="4" sm="6" cols="12">
                <UnitConverter
                  :label="$t('editTrip.gearLossStep.ropeLength')"
                  :value="gearLoss['ropeLength']"
                  unit="m"
                  viewOnly
                />
              </b-col>
              <b-col v-if="gearLoss['netLength']" lg="4" sm="6" cols="12">
                <UnitConverter
                  :label="$t('editTrip.gearLossStep.netLength')"
                  :value="gearLoss['netLength']"
                  unit="m"
                  viewOnly
                />
              </b-col>
              <b-col v-if="gearLoss['nbBuoys']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.gearLossStep.nbBuoys')"
                  :value="gearLoss['nbBuoys']"
                  type="text"
                />
              </b-col>
              <b-col v-if="gearLoss['buoyDesc']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.gearLossStep.buoyDesc')"
                  :value="gearLoss['buoyDesc']"
                  type="text"
                />
              </b-col>
              <b-col v-if="gearLoss['tags']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.gearLossStep.tags')"
                  :value="gearLoss['tags']"
                  type="text"
                />
              </b-col>
              <b-col v-if="gearLoss['direction']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.gearLossStep.direction')"
                  :value="gearLoss['direction']"
                  :options="systemsLists.cardinalPoints"
                  type="select"
                />
              </b-col>
              <b-col v-if="gearLoss['remark']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('editTrip.remarkTitle')"
                  :value="gearLoss['remark']"
                  type="text"
                />
              </b-col>
              <b-col v-if="gearLoss['closeDatetime']" lg="4" sm="6" cols="12">
                <LabelView
                  :label="$t('dataGroupClosureDate')"
                  :value="$showDate(gearLoss['closeDatetime'])"
                  type="text"
                />
              </b-col>
            </b-row>
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Geolocation from "@/utils/geolocation.js";
import LabelView from "@/components/LabelView.vue";
import GearLostOldViewer from "@/components/viewtrip/GearLostOldViewer.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  name: "GearLostViewer",
  components: {
    LabelView,
    GearLostOldViewer,
    UnitConverter
  },
  data: function() {
    return {};
  },
  computed: {
    ...mapState({
      subscription: state => state.currentSubscription,
      systemsLists: state => state.systemsLists,
      currentTrip: state => state.selectedTrip
    }),
    isOldGearLost() {
      // TODO: find a new way to detect old gear loss model, isOldModule is not served anymore
      // example of an old gearloss dev trip DSZFKY
      // {"creation":"2000-01-01T00:00:00.000000Z","status":39581,"occursAt":{"datetime":"2020-04-01T19:14:00.000000Z","latitude":48.3053,"longitude":-64.7114,"autolat":"G","autolon":"G"},"gearType":39500,"nbGear":2,"direction":25200,"closeDatetime":"2020-04-01T19:22:00.000000Z"}
      return false;
    }
  },
  watch: {
    systemsLists() {
      this.$router.go();
    }
  },
  methods: {
    convertDD2toDMS(position, type) {
      return Geolocation.convertDD2toDMS(position, type).toString;
    }
  },
  mounted() {}
};
</script>
