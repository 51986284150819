<template>
  <div :name="$options.name">
    <b-overlay
      rounded="sm"
      blur="none"
      :key="'inactivity' + inactivity['closeDatetime']"
    >
      <fieldset
        v-for="(detail, index) in inactivity.details"
        :key="'detail-' + index"
        class="tow"
      >
        <h4 class="level1">
          {{ $t("editTrip.inactivityStep.period") }}&nbsp;{{ index + 1 }}
          <span
            v-b-modal="'modal-detail-remark' + index"
            class="add-remark-btn-title"
            v-show="inactivity['closeDatetime'] === undefined"
          ></span>
          <Popup
            v-model="detail['remark']"
            :title-label="$t('editTrip.remarkTitle')"
            :maxlength="2000"
            :modalName="'modal-detail-remark' + index"
          />
        </h4>
        <fieldset :disabled="inactivity['closeDatetime'] !== undefined">
          <GenericInput
            :label="$t('editTrip.effortStep.licenceNumber')"
            v-model="detail['licenceNumber']"
            type="datalist"
            :options="operatorsFin"
            :otherValue="true"
            :required="true"
            :digits="true"
            :minlength="4"
            :maxlength="$const.LIC_NO_MAX_LENGTH"
            :min="1000"
            @error="addError"
            :refeshError="refreshError + inactivityFormRefresh"
            :key="'inactivity-licence-' + index"
          />
          <br />
          <DateInput
            :label="$t('editTrip.inactivityStep.start')"
            v-model="detail['start']"
            :required="true"
            :autodatetime="true"
            @error="addError"
            :refeshError="refreshError + inactivityFormRefresh"
          />
          <DateInput
            :label="$t('editTrip.inactivityStep.end')"
            v-model="detail['end']"
            :required="true"
            :autodatetime="true"
            @error="addError"
            :refeshError="refreshError + inactivityFormRefresh"
            :minDates="[
              {
                value: detail['start'],
                text: $t('editTrip.inactivityStep.afterStartDate')
              }
            ]"
          />
          <GenericInput
            :label="$t('editTrip.inactivityStep.reason')"
            v-model="detail['reason']"
            type="text"
            :maxlength="2000"
            :required="true"
            @error="addError"
            :refeshError="refreshError + inactivityFormRefresh"
          />
        </fieldset>
      </fieldset>
      <fieldset class="suppEntryButtonWrapper removeBottomPadding">
        <p>
          <input
            v-if="inactivity.details?.length > 1 && !isClosed"
            @click="removeReport()"
            class="removeSuppEntryButton"
            type="button"
            :value="
              $t('editTrip.inactivityStep.removeDetail') +
                String(inactivity.details.length)
            "
          />
          <input
            v-if="!isClosed"
            @click="addReport()"
            class="addSuppEntryButton removeBottomMargin"
            :class="inactivity.details?.length > 0 ? 'removeTopMargin' : null"
            type="button"
            :value="$t('editTrip.inactivityStep.addDetail')"
          />
        </p>
      </fieldset>
    </b-overlay>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import { keysMatchingValue, scrollToTopField } from "@/utils/utils";
import DateInput from "@/components/widgets/DateInput.vue";
import Popup from "@/components/widgets/Popup.vue";

export default {
  name: "InactivityForm",
  components: {
    GenericInput,
    DateInput,
    Popup
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    isClosed: {
      type: Boolean,
      required: true
    },
    refreshError: {
      type: Number,
      default: 0
    },
    addErrorToParent: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      inactivity: {
        details: []
      },
      inactivityFormRefresh: 0,
      name: "inactivityStep"
    };
  },
  computed: {
    ...mapState({
      currentPage: state => state.editTripSubform.currentPage,
      operatorsFin: state => state.editTripSubform.operatorsFin,
      stateErrors: state => state.editTripSubform.errors
    })
  },
  watch: {
    currentPage() {
      if (this.currentPage === this.name) {
        if (this.inactivity?.details?.length === 0) {
          this.addReport();
        }
      }
    },
    inactivity: {
      deep: true,
      handler: function() {
        this.$emit("input", this.inactivity);
      }
    },
    value: {
      deep: true,
      handler: function() {
        if (this.value && this.value.details) {
          this.inactivity = this.value;
        }
      }
    }
  },
  mounted() {
    this.inactivity = { ...this.inactivity, ...this.value };
  },
  methods: {
    removeReport() {
      this.inactivity.details = this.inactivity.details.slice(0, -1);
      if (this.inactivity.details.length === 0) {
        this.inactivity = {
          details: []
        };
        this.$emit("clear-data");
      }
      this.inactivity = JSON.parse(JSON.stringify(this.inactivity));
    },
    async addReport() {
      this.inactivityFormRefresh++;

      await this.$nextTick();
      const errorsFieldsNames = keysMatchingValue(
        this.stateErrors,
        true,
        "inactivityStep"
      );

      if (errorsFieldsNames.length > 0) {
        scrollToTopField(errorsFieldsNames);
        return;
      }

      const now = new Date();
      this.inactivity.details.push({
        start: null,
        end: null,
        licenceNumber:
          this.operatorsFin && this.operatorsFin.length === 1
            ? this.operatorsFin[0].text
            : null,
        reason: null,
        creation: now.toISOString()
      });

      if (this.inactivity.details.length === 1) {
        this.inactivity["creation"] = now.toISOString();
        this.inactivity["reportUID"] = this.$makeAlphaId(6);
      }
    },
    //Je le mets en commentaire le temps qu'une décision soit prise.
    /*lastTripDateInactivity(date, index) {
      for (let i in this.inactivity.details) {
        const detail = this.inactivity.details[i];
        if (
          index.toString() !== i.toString() &&
          new Date(date) >= new Date(detail.start) &&
          new Date(date) <= new Date(detail.end)
        ) {
          return this.$t("editTrip.inactivityStep.rangeOtherInactivity");
        }
      }

      return undefined;
    },*/
    addError(error) {
      this.addErrorToParent(error);
    }
  }
};
</script>
