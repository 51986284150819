import JobelClient from "@/service/api";
import axios from "axios";

// big objects should be free of nulls when sent to Jobel server
var copyObjectWithoutNull = function(object, nullEmptyObject = false) {
  if (typeof object !== "object" || object === null) return object;

  var result = {};
  const keys = Object.keys(object);
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    const element = object[key];
    if (element != null) {
      // otherwise, array are considered type object
      if (Array.isArray(element)) {
        const subArray = [];
        for (let j = 0; j < element.length; j++) {
          const subElement = copyObjectWithoutNull(element[j], nullEmptyObject);
          if (subElement) {
            subArray.push(subElement);
          }
        }
        if (subArray.length > 0 || nullEmptyObject) {
          result[key] = subArray;
        }
      } else if (typeof element === "object") {
        const subObject = copyObjectWithoutNull(element, nullEmptyObject);
        if (Object.keys(subObject).length > 0) {
          result[key] = subObject;
        } else if (nullEmptyObject) {
          result[key] = null;
        }
      } else {
        result[key] = element;
      }
    }
  }
  return result;
};

function getTrap(trapId, experimentalSections) {
  for (const sectionId in experimentalSections) {
    const section = experimentalSections[sectionId];
    for (const groupId in section.groups) {
      const group = section.groups[groupId];
      for (const gearId in group.gears) {
        const gear = group.gears[gearId];
        if (trapId == gear.id) {
          return {
            type: gear.type.id,
            number: parseInt(group.number)
          };
        }
      }
    }
  }
}

function generateLobsterPopulationSamples(trip, subscription) {
  if (!("experimentalData" in trip)) return;

  // Cannot proceed if the trip has no effort/tow
  if (!("efforts" in trip)) return;
  if (trip.efforts.length === 0) return;
  if (!("tows" in trip.efforts[0])) return;
  if (trip.efforts[0].tows.length === 0) return;

  // If samples already exist, don't override them.
  if ("lobsterPopulationSamples" in trip.efforts[0].tows[0]) return;

  // Scan the experimental data to fill the array of samples
  let samples = [];
  for (const trapId in trip.experimentalData) {
    const trap = getTrap(trapId, subscription.experimentalSections);
    if (!trap) continue;
    const table = trip.experimentalData[trapId];
    for (const rowIndex in table) {
      const row = table[rowIndex];
      for (const sexId in row) {
        const catchSize = row[sexId];
        if (catchSize != null) {
          samples.push({
            trapType: trap.type,
            trapNumber: trap.number,
            sex: parseInt(sexId),
            size: catchSize
          });
        }
      }
    }
  }
  trip.efforts[0].tows[0].lobsterPopulationSamples = samples;
}

export default {
  namespaced: true,
  state: {
    client: JobelClient.default(),
    language: null,
    token: null,
    error: null,
    monitor: false,
    testing: false
  },
  mutations: {
    setError(state, error) {
      state.error = error;
    },
    clearError(state) {
      state.error = null;
    },
    setToken(state, token) {
      state.token = token;
    },
    setMonitor(state, monitor) {
      state.monitor = monitor;
    },
    setTesting(state, testing) {
      state.testing = testing;
    }
  },
  getters: {
    isOnline: state => {
      return state.error == null;
    },
    getOnlineError: state => {
      return state.error;
    },
    isAuthenticated: state => {
      return state.token;
    }
  },
  actions: {
    setToken({ commit }, token) {
      commit("setToken", token);
    },
    post({ dispatch, commit, state, rootState }, { action, data, timeout }) {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }

      if (state.token) {
        formData.append("offlineToken", state.token);
      }
      if (rootState.language) {
        formData.append("language", rootState.language);
      }

      // TODO: check if null options works on client.post instead of this if/else
      let postPromise;
      if (timeout) {
        postPromise = state.client.post(`jc/${action}/`, formData, {
          timeout: timeout
        });
      } else {
        postPromise = state.client.post(`jc/${action}/`, formData);
      }

      return postPromise
        .then(response => {
          commit("clearError");
          return response;
        })
        .catch(error => {
          if (error.errorType == "session") {
            dispatch("lostSession", null, { root: true }).finally(() =>
              dispatch("setToken", null)
            );
            // .then(() =>
            //   Promise.reject(error)
            // );
          }
          if (["timeout", "unavailable", "network"].includes(error.errorType)) {
            commit("setError", error.i18n);
          }
          throw error;
        });
    },
    needInternet({ dispatch, commit, state }, isNeeded = true) {
      if (isNeeded != state.monitor) commit("setMonitor", isNeeded);

      if (state.monitor) {
        if (state.testing) {
          return Promise.resolve(true);
        }
        commit("setTesting", true);
        return dispatch("testConnection")
          .catch(error => {
            setTimeout(() => {
              if (state.monitor) {
                dispatch("needInternet");
              }
            }, 5000);
          })
          .finally(() => commit("setTesting", false));
      }
      return Promise.resolve(true);
    },
    login({ dispatch, commit }, form) {
      return dispatch("post", { action: "login", data: form }).then(data => {
        commit("setToken", data.offlineToken);
        return Promise.resolve(data);
      });
    },
    logout({ dispatch }) {
      return dispatch("post", { action: "logout" }).finally(() =>
        dispatch("setToken", null)
      );
    },
    signup({ dispatch }, { fin, captcha }) {
      return dispatch("post", {
        action: "signup",
        data: {
          fin: fin,
          captcha_text: captcha.text,
          captcha_id: captcha.id
        }
      });
    },
    signupComplete({ dispatch }, { fin, captcha, password, form }) {
      // most fields are correctly named but some divergences
      let data = {
        fin: fin,
        captcha_text: captcha.text,
        captcha_id: captcha.id,
        password: password,
        fisherIdentificationNumber: fin,
        first_name: form.firstname,
        last_name: form.lastname,
        phoneNumber: "+1" + form.phoneNumber
      };

      const samefields = [
        "address",
        "province",
        "timezone",
        "email",
        "dfoRegion",
        "securityQuestion1",
        "answer1",
        "securityQuestion2",
        "answer2",
        "securityQuestion3",
        "answer3",
        "termsAndConditionsAccepted"
      ];
      for (const key of samefields) {
        data[key] = form[key];
      }

      return dispatch("post", {
        action: "signupComplete",
        data: data
      });
    },
    getSystemsLists({ dispatch }, hash = null) {
      let data = {};
      if (hash) {
        data["updated"] = hash;
      }
      return dispatch("post", { action: "getSystemsLists", data: data }).then(
        data => {
          return Promise.resolve(data.systemsLists);
        }
      );
    },
    getUserProfile({ dispatch }) {
      return dispatch("post", { action: "getUserProfile" }).then(data => {
        return Promise.resolve(data.profile);
      });
    },
    editUserProfile({ dispatch }, form) {
      // most fields are correctly named but some divergences
      let data = {
        fisherIdentificationNumber: form.username,
        first_name: form.firstname,
        last_name: form.lastname,
        phoneNumber: "+1" + form.phoneNumber
      };
      const samefields = [
        "address",
        "province",
        "timezone",
        "email",
        "dfoRegion",
        "securityQuestion1",
        "answer1",
        "securityQuestion2",
        "answer2",
        "securityQuestion3",
        "answer3",
        "termsAndConditionsAccepted"
      ];
      for (const key of samefields) {
        data[key] = form[key];
      }

      return dispatch("post", {
        action: "editUserProfile",
        data: data
      }).then(data => {
        return Promise.resolve(data.profile);
      });
    },
    editElogKey({ dispatch }, key) {
      return dispatch("post", {
        action: "editElogKey",
        data: { elogkey: key }
      });
    },
    editPassword({ dispatch }, { oldPassword, newPassword }) {
      return dispatch("post", {
        action: "editPassword",
        data: {
          old_password: oldPassword,
          new_password1: newPassword,
          new_password2: newPassword
        }
      });
    },
    resetPassword({ dispatch }, { fin, captcha }) {
      return dispatch("post", {
        action: "resetPassword",
        data: {
          fin: fin,
          captcha_text: captcha.text,
          captcha_id: captcha.id
        }
      });
    },
    newPassword({ dispatch }, { user, token, password }) {
      return dispatch("post", {
        action: "newPassword",
        data: {
          user: user,
          token: token,
          password1: password,
          password2: password
        }
      });
    },
    verifyToken({ dispatch }, { user, token }) {
      return dispatch("post", {
        action: "verifyToken",
        data: {
          user: user,
          token: token
        }
      });
    },
    getAvailableModules({ dispatch }) {
      return dispatch("post", { action: "getAvailableModules" }).then(data => {
        return Promise.resolve(data.modules);
      });
    },
    getOrder({ dispatch }, uuid) {
      return dispatch("post", {
        action: "getOrder",
        data: { module: uuid }
      }).then(data => {
        return {
          isPaid: data?.isPaid,
          stripeKey: data.stripeKey,
          clientSecret: data.clientSecret,
          expiration: data.expiration
        };
      });
    },
    processOrder({ dispatch }, data) {
      return dispatch("post", { action: "processOrder", data: data });
    },
    getTransactions({ dispatch }) {
      return dispatch("post", { action: "getTransactions" }).then(data => {
        return Promise.resolve(data.transactions);
      });
    },
    getSubscriptions({ dispatch }) {
      return dispatch("post", {
        action: "getSubscriptions",
        timeout: 20000
      }).then(data => {
        // TODO: This is temporary: post-process the subscriptions to recreate the experimentalSections if needed.
        data.subscriptions.forEach(subscription => {
          if (subscription.lobsterPopulationSampler) {
            subscription.experimentalSections = {
              "27": {
                id: 27,
                title: "Mesure de homards pour le RPPSG",
                groups: {
                  "53": {
                    id: 53,
                    number: "1",
                    gears: {
                      "105": {
                        id: 105,
                        type: {
                          id: 1,
                          abbrv: "R",
                          description: "Engin régulier"
                        },
                        allowedCatchTypes: {
                          "1": {
                            id: 1,
                            value_min: 1,
                            value_max: 14,
                            abbrv: "M",
                            description: "Mâle"
                          },
                          "2": {
                            id: 2,
                            value_min: 1,
                            value_max: 14,
                            abbrv: "F",
                            description: "Femelle"
                          },
                          "3": {
                            id: 3,
                            value_min: 1,
                            value_max: 14,
                            abbrv: "FO",
                            description: "Femelle oeuvée"
                          }
                        }
                      },
                      "106": {
                        id: 106,
                        type: {
                          id: 2,
                          abbrv: "M",
                          description: "Engin modifié"
                        },
                        allowedCatchTypes: {
                          "1": {
                            id: 1,
                            value_min: 1,
                            value_max: 14,
                            abbrv: "M",
                            description: "Mâle"
                          },
                          "2": {
                            id: 2,
                            value_min: 1,
                            value_max: 14,
                            abbrv: "F",
                            description: "Femelle"
                          },
                          "3": {
                            id: 3,
                            value_min: 1,
                            value_max: 14,
                            abbrv: "FO",
                            description: "Femelle oeuvée"
                          }
                        }
                      }
                    }
                  },
                  "54": {
                    id: 54,
                    number: "2",
                    gears: {
                      "107": {
                        id: 107,
                        type: {
                          id: 1,
                          abbrv: "R",
                          description: "Engin régulier"
                        },
                        allowedCatchTypes: {
                          "1": {
                            id: 1,
                            value_min: 1,
                            value_max: 14,
                            abbrv: "M",
                            description: "Mâle"
                          },
                          "2": {
                            id: 2,
                            value_min: 1,
                            value_max: 14,
                            abbrv: "F",
                            description: "Femelle"
                          },
                          "3": {
                            id: 3,
                            value_min: 1,
                            value_max: 14,
                            abbrv: "FO",
                            description: "Femelle oeuvée"
                          }
                        }
                      },
                      "108": {
                        id: 108,
                        type: {
                          id: 2,
                          abbrv: "M",
                          description: "Engin modifié"
                        },
                        allowedCatchTypes: {
                          "1": {
                            id: 1,
                            value_min: 1,
                            value_max: 14,
                            abbrv: "M",
                            description: "Mâle"
                          },
                          "2": {
                            id: 2,
                            value_min: 1,
                            value_max: 14,
                            abbrv: "F",
                            description: "Femelle"
                          },
                          "3": {
                            id: 3,
                            value_min: 1,
                            value_max: 14,
                            abbrv: "FO",
                            description: "Femelle oeuvée"
                          }
                        }
                      }
                    }
                  }
                }
              }
            };
          }
        });
        return Promise.resolve(data.subscriptions);
      });
    },
    getInactivities({ dispatch }, data) {
      const params = { id: data.id };
      if (data?.lastmodifiedtimestamp) {
        params.lastmodifiedtimestamp = data.lastmodifiedtimestamp;
      }

      return dispatch("post", {
        action: "getInactivities",
        data: params,
        timeout: 20000
      }).then(function(data) {
        var formList = data.inactivities;
        for (let form of formList) {
          form.inactivity = {
            details: form.details,
            reportUID: form.reportUID,
            remark: form.remark
          };
          // TODO: verify if we still need moving details, remark, etc
          // delete form.details;
          // delete form.remark;
        }

        return Promise.resolve(data);
      });
    },
    getTrips({ dispatch }, data) {
      const params = { subscriptionUUID: data.subscriptionUUID };
      if (data?.lastmodifiedtimestamp) {
        params.lastmodifiedtimestamp = data.lastmodifiedtimestamp;
      }

      return dispatch("post", {
        action: "getTrips",
        data: params,
        timeout: 20000
      });
    },
    getTrip({ dispatch }, form) {
      return dispatch("post", {
        action: "getTrip",
        data: form,
        timeout: 20000
      }).then(data => {
        return Promise.resolve(data.trip);
      });
    },
    getArchives({ dispatch }, form) {
      return dispatch("post", {
        action: "getArchives",
        data: form,
        timeout: 20000
      }).then(data => {
        return Promise.resolve(data.trips);
      });
    },
    getXML({ dispatch }, fileId) {
      return dispatch("post", {
        action: "getXML",
        data: { fid: fileId }
      });
    },
    editModuleSettings({ dispatch, rootGetters }, form) {
      const subscription = rootGetters.SUBSCRIPTION;
      var data = copyObjectWithoutNull(form, true);
      return dispatch("post", {
        action: "editModuleSettings",
        data: {
          subscriptionUUID: subscription.uuid,
          settings: JSON.stringify(data)
        }
      });
    },
    pushInactivity({ dispatch }, { uuid, form }) {
      var data = copyObjectWithoutNull(form);
      let inactivity = { ...data, ...data.inactivity }; // flatten before sending
      delete inactivity.inactivity;

      return dispatch("post", {
        action: "pushInactivity",
        data: {
          id: uuid,
          inactivity: JSON.stringify(inactivity)
        }
      });
    },
    pushTrip({ dispatch, rootGetters }, { uuid, form }) {
      generateLobsterPopulationSamples(form, rootGetters.SUBSCRIPTION);

      form.completedBy = process.env.VUE_APP_VERSION;
      var trip = copyObjectWithoutNull(form);

      // Clean the trip here instead of cleaning the form (above).
      // This preserves the form in case the transmission fails.
      if ("experimentalData" in trip) {
        delete trip.experimentalData;
      }

      return dispatch("post", {
        action: "pushTrip",
        data: {
          subscriptionUUID: uuid,
          trip: JSON.stringify(trip)
        }
      });
    },
    testConnection({ dispatch }) {
      return dispatch("post", { action: "testConnection", data: { d: true } });
    }
  }
};
