<template>
  <div>
    <h4 class="level3">
      {{ $t("lobsterPopulationSamples.title") }}
    </h4>
    <table>
      <tr>
        <th>{{ $t("lobsterPopulationSamples.trap") }}</th>
        <th>{{ $t("lobsterPopulationSamples.sex") }}</th>
        <th>{{ $t("lobsterPopulationSamples.size") }}</th>
      </tr>
      <tr
        v-for="(sample, sampleIndex) in samples"
        :key="'lobsterPopulationSample-' + sampleIndex"
      >
        <td>{{ sample.trapType + sample.trapNumber }}</td>
        <td>{{ sample.sex }}</td>
        <td>{{ sample.size }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "LobsterPopulationSamplesViewer",
  props: {
    samples: {
      type: Array,
      required: true
    }
  },
  computed: {
    list1() {
      return [];
    }
  }
};
</script>

<style scoped>
table,
th,
td {
  border: 1px solid black;
  text-align: center;
}
</style>
