<template>
  <div>
    <TransfersViewer
      v-if="currentTrip.transfers && currentTrip.transfers.length > 0"
      :transfers="currentTrip.transfers"
    />
    <TransportViewer
      v-if="currentTrip.transport"
      :transport="currentTrip.transport"
    />
    <PersonalUseViewer
      v-if="currentTrip.personnalUses && currentTrip.personnalUses.length > 0"
      :personalUses="currentTrip.personnalUses"
    />
    <HailViewer
      v-if="currentTrip.hailIns && currentTrip.hailIns.length"
      :hailIns="currentTrip.hailIns"
    />
    <ArrivalViewer v-if="currentTrip.arrival" :arrival="currentTrip.arrival" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import TransfersViewer from "./TransfersViewer.vue";
import ArrivalViewer from "./ArrivalViewer.vue";
import PersonalUseViewer from "./PersonalUseViewer.vue";
import HailViewer from "./HailViewer.vue";
import TransportViewer from "./TransportViewer.vue";

export default {
  name: "LastViewer",
  components: {
    TransportViewer,
    PersonalUseViewer,
    TransfersViewer,
    ArrivalViewer,
    HailViewer
  },
  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists,
      currentTrip: state => state.selectedTrip
    })
  }
};
</script>
