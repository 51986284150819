<template>
  <div class="page-main">
    <h1>{{ $t("connexion.finUsed") }}</h1>

    <div class="topPageMenu">
      <router-link :to="{ name: 'connexion' }">
        {{ $t("cancel") }}
      </router-link>
    </div>

    <p class="middleText">
      {{ $t("connexion.finProvided") }}
      <span class="fin">{{ fin }}</span>
    </p>

    <div class="question">
      <span class="pastille">1</span>
      <p class="middleText">{{ $t("connexion.signupFail1") }}</p>
      <button @click="question1 = true" :class="question1 === true && 'active'">
        {{ $t("yes") }}
      </button>
      <button
        @click="question1 = false"
        :class="question1 === false && 'active'"
      >
        {{ $t("no") }}
      </button>
    </div>

    <div v-show="isSure" class="question">
      <span class="pastille">2</span>
      <p class="middleText">{{ $t("connexion.signupFail2") }}</p>
      <button @click="question2 = true" :class="question2 === true && 'active'">
        {{ $t("yes") }}
      </button>
      <button
        @click="question2 = false"
        :class="question2 === false && 'active'"
      >
        {{ $t("no") }}
      </button>
    </div>

    <div v-show="didUse" class="question">
      <span class="pastille">3</span>
      <p class="middleText">{{ $t("connexion.signupFail3") }}</p>
      <button @click="question3 = true" :class="question3 === true && 'active'">
        {{ $t("yes") }}
      </button>
      <button @click="resetPassword">{{ $t("no") }}</button>
    </div>

    <div>
      <!-- keep outer div for children v-shows, otherwise they dont always work -->
      <div v-show="isSureNot" class="response">
        <p v-if="french" class="middleText">
          Vous allez être retourné à la page d'inscription.
        </p>
        <p v-else-if="english" class="middleText">
          You are going to be redirected back to the signup page.
        </p>
        <button @click="gotoSignup">OK</button>
      </div>

      <div v-show="didNotUse" class="response">
        <p v-if="french" class="middleText">
          Vous allez être redirigé vers notre page de contact de support
          technique.
        </p>
        <p v-else-if="english" class="middleText">
          You are going to be redirected to technical support contact page.
        </p>
        <button @click="gotoHelp">OK</button>
      </div>

      <div v-show="rememberPassword" class="response">
        <p v-if="french" class="middleText">
          Vous avez déjà un compte Jobel.<br /><br />Vous allez être redirigé
          sur la page de connexion à votre compte pour y entrer votre NIP et
          votre mot de passe.
        </p>
        <p v-else-if="english" class="middleText">
          You already have a Jobel account.<br /><br />You are going to be
          redirected to the connection page where you have to enter your FIN and
          password to get inside your Jobel account.
        </p>
        <button @click="gotoLogin">OK</button>
      </div>
    </div>
  </div>
</template>

<script>
import DB from "@/service/db";
import { mapActions } from "vuex";

export default {
  name: "connexion-signup-fail",
  data() {
    return {
      fin: null,
      captcha: null,
      question1: null,
      question2: null,
      question3: null,
      refeshError: false,
      badPassword: false,
      badInternet: false
    };
  },
  computed: {
    isSure() {
      return this.question1 === true;
    },
    isSureNot() {
      return this.question1 === false;
    },
    didUse() {
      return this.isSure && this.question2 === true;
    },
    didNotUse() {
      return this.isSure && this.question2 === false;
    },
    rememberPassword() {
      return this.didUse && this.question3 === true;
    },
    french() {
      return this.$i18n.locale == "fr";
    },
    english() {
      return this.$i18n.locale == "en";
    }
  },
  methods: {
    ...mapActions("api", ["resetPassword"]),
    gotoSignup() {
      this.$router.push({ name: "connexionsignup" });
    },
    gotoHelp() {
      this.$router.push({ name: "signuphelp" });
    },
    gotoLogin() {
      this.$router.push({ name: "connexion" });
    },
    reset() {
      const vuejs = this;
      const fin = vuejs.fin;
      const captcha = vuejs.captcha;
      vuejs.$store
        .dispatch("showLoadingOverlay")
        .then(() => vuejs.resetPassword({ fin: fin, captcha: captcha }))
        .then(() =>
          vuejs.$router.replace({ name: "connexionresetmail" }).catch(error => {
            // ignore because of exception on navigation guard...
          })
        )
        .catch(error => {
          if (error.errorType == "user") {
            // these errors can only be caused by captcha mecanic failures, there is no user entered data managed on this page.
            // Recovery is going back to signup
            vuejs.$store.dispatch(
              "alert/error",
              vuejs.$i18n.t("message.technical_error")
            );
            vuejs.$router.replace("/connexion");
          } else {
            vuejs.$alertUserApiError(error);
          }
        })
        .finally(() => vuejs.$store.dispatch("hideLoadingOverlay"));
    }
  },
  beforeMount() {
    const vm = this;
    DB.getItem("signup").then(function(tokens) {
      vm.fin = tokens.fin;
      vm.captcha = tokens.captcha;
    });
  }
};
</script>

<style lang="scss" scoped>
.topPageMenu {
  color: $darkColor;
  padding-bottom: 25px;

  a {
    color: $darkColor;
    display: block;
    text-align: center;
    padding: 7px;

    &:hover {
      text-decoration: underline;
    }
  }

  .separator {
    display: none;
  }
}

.middleText {
  .fin {
    font-size: 2.5em;
    display: block;
  }
  & {
    font-size: 1.2em;
    color: black;
    text-align: center;
    padding: 0px 25px;
  }
}

.pastille {
  width: 25px;
  height: 25px;
  background: $darkColor;
  display: inline-block;
  color: white;
  border-radius: 100%;
  line-height: 25px;
  margin-bottom: 10px;
}

input#okButton {
  margin-left: auto;
  margin-right: auto;
}
input#okButton,
input#noButton,
input#yesButton {
  width: 100%;
  padding: 0;

  &.active {
    background: $darkColor;
  }

  @media screen and (max-width: 514.9px) {
    width: 94%;
  }
}

.response,
.question {
  text-align: center;
  margin-top: 30px;
}

button {
  border: none;
  margin-top: 11px;
  width: 438px;
  height: 53px;
  background-color: $lightColor;
  font-size: 1em;
  color: white;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: $darkColor;
  }

  &.active {
    background-color: $darkColor;
  }
}
</style>
