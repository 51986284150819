<template>
  <b-container v-if="usedQuotas.length" :name="$options.name" class="quotaBar">
    <b-row v-for="(quota, index) in usedQuotas" :key="index">
      <b-col class="quotaLabel darkColor" cols="12">
        {{ $t("quota.title") }}
        {{ quota.maxWeight | round }}&nbsp;kg ({{
          quota.maxWeight | convert | round
        }}&nbsp;lb)
      </b-col>
      <b-col cols="12">
        <b-progress :max="quota.maxWeight" height="2em">
          <b-progress-bar
            :value="quota.keptWeight"
            :precision="0"
            class="quotaUsed"
          >
          </b-progress-bar>
          <b-progress-bar
            :value="quota.leftWeight"
            :precision="0"
            class="quotaLeft"
          >
          </b-progress-bar>
        </b-progress>
        <b-row class="darkColor quotaDetail" no-gutters>
          <b-col>
            {{ quota.keptWeight | round }}&nbsp;kg ({{
              quota.keptWeight | convert | round
            }}&nbsp;lb)
          </b-col>
          <b-col style="text-align: right;">
            {{ quota.leftWeight | round }}&nbsp;kg ({{
              quota.leftWeight | convert | round
            }}&nbsp;lb)
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "QuotaBar",
  filters: {
    round(value) {
      return Math.round(value);
    },
    convert(value) {
      return value * 2.2046226218;
    }
  },
  computed: {
    ...mapState({
      subscription: state => state.currentSubscription,
      openTrip: state => state.currentOpenTrip,
      tripList: state => state.currentTripList,
      closedTrips: state => state.closedTrips,
      licences: state =>
        Object.values(state.currentSubscription.preferences.licences)
    }),
    usedQuotas() {
      let result = [];
      for (const licence of this.licences || []) {
        for (const quota of licence.quotas || []) {
          let usedQuota = {
            keptWeight: 0,
            maxWeight: quota.weight
          };
          for (let trip of this.tripList) {
            usedQuota.keptWeight += this.getLandingWeight(
              trip,
              licence.target,
              quota.start,
              quota.end
            );
          }
          for (let trip of this.closedTrips) {
            usedQuota.keptWeight += this.getLandingWeight(
              trip,
              licence.target,
              quota.start,
              quota.end
            );
          }
          usedQuota.keptWeight += this.getLandingWeight(
            this.openTrip,
            licence.target,
            quota.start,
            quota.end
          );

          usedQuota.leftWeight = usedQuota.maxWeight - usedQuota.keptWeight;
          if (usedQuota.leftWeight < 0) usedQuota.leftWeight = 0;

          const ratio = (usedQuota.keptWeight / usedQuota.maxWeight) * 100;

          if (ratio < 75) {
            usedQuota.color = "success";
          } else if (ratio < 100) {
            usedQuota.color = "warning";
          } else {
            usedQuota.color = "danger";
          }

          result.push(usedQuota);
        }
      }
      return result;
    }
  },
  methods: {
    getLandingWeight(trip, target, min, max) {
      // The landing weight is obtained from the arrival cargo events with cargos of the target species.
      // quota is used to check if this cargo event happened in the correct period of time -- why is this the responsibility of this function ?
      if (!trip || !target || !min || !max) return 0;

      let creation;
      if (trip.sortingDatetime) {
        creation = new Date(trip.sortingDatetime);
      } else if (trip.departure && trip.departure.datetime) {
        creation = new Date(trip.departure.datetime);
      } else if (trip.arrival && trip.arrival.datetime) {
        creation = new Date(trip.arrival.datetime);
      } else {
        return 0;
      }

      let landingWeight = 0;

      const start = new Date(min);
      const end = new Date(max);

      if (creation >= start && creation <= end) {
        if (trip.arrival && trip.arrival.cargoEvents) {
          for (const cargoEvent of trip.arrival.cargoEvents) {
            if (cargoEvent.datetime) {
              const datetime = new Date(cargoEvent.datetime);
              if (datetime < start || datetime > end) {
                continue;
              }
            }
            if (cargoEvent.cargos) {
              for (const cargo of cargoEvent.cargos) {
                if (cargo.species === target && cargo.weight) {
                  landingWeight += cargo.weight;
                }
              }
            }
          }
        }
      }

      return landingWeight;
    }
  }
};
</script>

<style lang="scss" scoped>
.darkColor {
  color: $darkColor;
}

.quotaLabel {
  font-weight: bold;
  margin-bottom: 2px;
  text-align: center;
}

.quotaDetail {
  margin-top: 2px;
}

.quotaUsed {
  background: $darkColor;
}

.quotaLeft {
  background: $mediumColor;
}

.quotaBar {
  background-color: white;
  border: 2px solid $darkColor;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
}
</style>
