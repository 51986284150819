<template>
  <div v-if="hasPersonalUse" :name="$options.name">
    <h4 class="level1" :class="personalUses.length > 0 ? 'nextIsTitle' : null">
      {{ $t("personalUse.listTitle") }}
    </h4>
    <fieldset
      v-for="(personal, index) in personalUses"
      :key="'personalUse-' + index"
      class="removeBottomPadding"
    >
      <h4 class="level2">
        {{ $t("personalUse.itemTitle") }} {{ index + 1 }}
        <span
          v-b-modal="'personalUseRemark-' + index"
          v-show="personal.closeDatetime === undefined"
          class="add-remark-btn-title"
        ></span>
        <Popup
          v-model="personal.remark"
          :title-label="$t('editTrip.remarkTitle')"
          :maxlength="2000"
          :modalName="'personalUseRemark-' + index"
        />
      </h4>

      <b-overlay
        :show="personal.closeDatetime !== undefined"
        rounded="sm"
        blur="none"
        :key="'personal' + index + personal.closeDatetime"
      >
        <template #overlay>
          <b-icon icon="file-lock" variant="danger" scale="2"></b-icon>
          <p class="redMessage">{{ $t("closeMsg") }}</p>
          <p class="redMessage">
            {{ $t("closeMsgDate") }}{{ $showDate(personal.closeDatetime) }}
          </p>
        </template>
        <fieldset :disabled="personal.closeDatetime !== undefined">
          <Select
            :label="$t('editTrip.lastStep.specie')"
            v-model="personal.species"
            :options="catchSpecies"
            :required="true"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshByIndex[index]"
          />
          <Select
            v-if="hasMultiplePersonalUseProductForms"
            :label="$t('editTrip.lastStep.productForm')"
            v-model="personal.productForm"
            :options="personalUseProductFormOptions"
            :required="true"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshByIndex[index]"
          />
          <UnitConverter
            v-if="hasPersonalUseWeight"
            :label="$t('editTrip.lastStep.weight')"
            v-model="personal.weight"
            :required="
              hasPersonalUseWeight === $const.MANDATORY ||
                atLeastOneFieldRequired(personal)
            "
            :min="0"
            :max="speciesMaxWeight(personal)"
            unit="weight"
            @error="
              error => {
                addError(error, index);
              }
            "
            :forceErrorMsg="errorMessageWeight(personal.species)"
            :refresh="showErrorData + refreshByIndex[index]"
          />
          <Select
            v-if="hasPersonalUseId"
            :label="$t('personalUse.usage')"
            v-model="personal.usage"
            :options="usageOptions"
            :required="hasPersonalUseId === $const.MANDATORY"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshByIndex[index]"
          />
          <Select
            v-if="hasPersonalUseSize"
            :label="$t('editTrip.lastStep.size')"
            v-model="personal.size"
            :options="trapSizesOptionsBySpecie(personal.species)"
            :required="hasPersonalUseSize === $const.MANDATORY"
            @error="
              error => {
                addError(error, index);
              }
            "
            :refeshError="showErrorData + refreshByIndex[index]"
          />
          <IntegerInput
            v-if="hasPersonalUseNbSpecimens"
            :label="$t('catch.keptNbSpecimen')"
            v-model="personal.kept"
            :min="0"
            :max="speciesMaxQuantity(personal)"
            :required="
              hasPersonalUseNbSpecimens === $const.MANDATORY ||
                atLeastOneFieldRequired(personal)
            "
            @error="
              error => {
                addError(error, index);
              }
            "
            :forceErrorMsg="errorMessageQuantity(personal.species)"
            :refeshError="showErrorData + refreshByIndex[index]"
          />
          <p
            v-if="atLeastOneFieldRequired(personal)"
            v-html="$t('personalUse.message')"
          />
        </fieldset>
        <fieldset class="suppEntryButtonWrapper removeBottomPadding">
          <p>
            <input
              v-if="!personal.closeDatetime"
              @click="closeUsage(index)"
              class="closeSuppEntryButton"
              type="button"
              :class="
                index + 1 < personalUses.length
                  ? 'addBottomMargin'
                  : 'removeBottomMargin'
              "
              :value="$t('personalUse.close') + String(index + 1)"
            />
          </p>
        </fieldset>
      </b-overlay>
    </fieldset>

    <fieldset class="suppEntryButtonWrapper removeTopMargin">
      <p>
        <input
          v-if="personalUses.length > 0 && !checkCloseDatePersonnal"
          @click="removeUsage"
          class="removeSuppEntryButton"
          type="button"
          :value="$t('personalUse.remove') + String(personalUses.length)"
        />
        <input
          @click="addUsage"
          class="addSuppEntryButton"
          type="button"
          :value="$t('personalUse.add')"
        />
      </p>
    </fieldset>
  </div>
</template>

<script>
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import Select from "@/components/Select.vue";
import Vue from "vue";

import { mapActions, mapGetters, mapState } from "vuex";
import { keysMatchingValue, scrollToTopField } from "@/utils/utils";
import UnitConverter from "@/components/widgets/UnitConverter.vue";
import Popup from "@/components/widgets/Popup.vue";

import PersonalUseModel from "@/models/PersonalUseModel.js";

class Summary {
  specie = null;
  weight = null;
  quantity = null;

  constructor(specie) {
    this.specie = specie;
  }

  hasWeight() {
    return this.weight > 0;
  }

  hasQuantity() {
    return this.quantity > 0;
  }
}

export default {
  name: "PersonalUsage",
  components: {
    Select,
    IntegerInput,
    Popup,
    UnitConverter
  },
  props: {
    showErrorDataParent: {
      type: Number,
      required: true
    },
    addErrorToParent: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      showErrorData: 0,
      type: "usageStep",
      personalUses: [],
      refreshByIndex: [],
      hasTrapSize: false,
      catchSpecies: [],
      catchSummary: new Map(),
      mapMaxWeight: new Map()
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue", "buildOptions"]),
    ...mapState({
      systemsLists: state => state.systemsLists,
      productForms: state => state.systemsLists.productForm,
      form: state => state.currentSubscription.module.form,
      stateErrors: state => state.editTripSubform.errors,
      openTrip: state => state.currentOpenTrip,
      subform: state => state.editTripSubform.subform
    }),
    hasPersonalUse() {
      return this.getPropertyValue("hasPersonalUse", {
        subforms: this.subform
      });
    },
    hasPersonalUseNbSpecimens() {
      return this.getPropertyValue("hasPersonalUseNbSpecimens", {
        subforms: this.subform
      });
    },
    hasPersonalUseId() {
      return this.getPropertyValue("hasPersonalUseId", {
        subforms: this.subform
      });
    },
    hasPersonalUseSize() {
      return this.getPropertyValue("hasPersonalUseSize", {
        subforms: this.subform
      });
    },
    hasPersonalUseProductForm() {
      return this.getPropertyValue("hasPersonalUseProductForm", {
        subforms: this.subform
      });
    },
    hasPersonalUseWeight() {
      return this.getPropertyValue("hasPersonalUseWeight", {
        subforms: this.subform
      });
    },
    usageOptions() {
      return [
        {
          value: null,
          text: "---------",
          disabled: this.hasPersonalUseId === this.$const.MANDATORY
        },
        ...this.systemsLists.catchUsages
      ];
    },
    personalUseProductForms() {
      return this.getPropertyValue(
        "personalUseProductForms",
        { form: this.form },
        []
      );
    },
    personalUseProductFormOptions() {
      return this.buildOptions(this.personalUseProductForms, this.productForms);
    },
    hasMultiplePersonalUseProductForms() {
      return this.personalUseProductForms.length > 0;
    },
    checkCloseDatePersonnal() {
      if (this.personalUses.length > 0) {
        let lastIndex = this.personalUses.length - 1;
        return this.personalUses[lastIndex].closeDatetime != null;
      }
      return false;
    }
  },
  watch: {
    personalUses: {
      handler: function(val) {
        this.$emit("update-personalUsages", val);
        this.selectDefaultSize();
      },
      deep: true
    },
    showErrorDataParent() {
      ++this.showErrorData;
    },
    hasPersonalUseId: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          this.personalUses.forEach(personalUse => {
            personalUse.usage = null;
          });
        }
      }
    },
    hasPersonalUseProductForm: {
      immediate: true,
      handler: function(state) {
        const productForm =
          state === this.$const.BLOCKED ? null : this.$const.FORM_ROUND;
        this.personalUses.forEach(personalUse => {
          personalUse.productForm = productForm;
        });
      }
    },
    "openTrip.efforts": {
      immediate: true,
      deep: true,
      handler: function(efforts) {
        this.updateCatchSpecies(efforts);
        this.updateHasTrapSize(efforts);
      }
    }
  },
  methods: {
    ...mapActions(["closeObject", "addErrorsToState"]),
    title(species, productForm) {
      const specie = this.catchSpecies.find(x => x.value === species)?.text;
      const form = this.systemsLists.productForm.find(
        x => x.value == productForm
      )?.text;
      let result = `${specie} (${form})`;
      return result;
    },
    selectDefaultSize() {
      if (!this.hasTrapSize) {
        return;
      }
      for (let personalUse of this.personalUses) {
        const specie = personalUse.species;
        if (specie == null) {
          continue;
        }
        const result = this.getPropertyValue("hasMoreOptionsInSize", {
          catchSpecies: specie
        });
        const options = result
          ? this.$t("sizeOptionsLobster.items")
          : this.$t("sizeOptions.items");
        const currentSize = personalUse.size;
        if (options.length === 2 && currentSize !== options[1].value) {
          personalUse.size = options[1].value;
        }
      }
      this.$forceUpdate();
    },
    speciesMaxWeight(personal) {
      if (this.catchSummary.has(personal.species)) {
        const s = this.catchSummary.get(personal.species);
        if (s.hasWeight()) {
          return s.weight;
        }
      }
      return undefined;
    },
    speciesMaxQuantity(personal) {
      if (this.catchSummary.has(personal.species)) {
        const s = this.catchSummary.get(personal.species);
        if (s.hasQuantity()) {
          return s.quantity;
        }
      }
      return undefined;
    },
    errorMessageWeight(specie) {
      let declaredWeight = 0;
      const s = this.catchSummary.get(specie);
      if (s && s.hasWeight()) {
        this.personalUses.forEach(personal => {
          if (personal.species == specie) {
            declaredWeight += personal.weight;
          }
        });

        if (declaredWeight > s.weight) {
          return this.$t("editTrip.lastStep.weightError", { max: s.weight });
        }
      }
      return "";
    },
    errorMessageQuantity(specie) {
      let declaredQuantity = 0;
      const s = this.catchSummary.get(specie);
      if (s && s.hasQuantity()) {
        this.personalUses.forEach(personal => {
          if (personal.species == specie) {
            declaredQuantity += personal.kept;
          }
        });

        if (declaredQuantity > s.quantity) {
          const label = this.$t("editTrip.lastStep.quantityError");
          return `${label} ${s.quantity}`;
        }
      }
      return "";
    },
    updateCatchSpecies(efforts) {
      this.catchSummary.clear();
      let species = [];

      if (efforts == null || efforts.length == 0) {
        return;
      }

      this.mapMaxWeight.clear();
      efforts.forEach(effort => {
        effort.tows.forEach(tow => {
          tow.catches.forEach(catchItem => {
            let s;
            if (this.catchSummary.has(catchItem.species)) {
              s = this.catchSummary.get(catchItem.species);
            } else {
              s = new Summary(catchItem.species);
              this.catchSummary.set(catchItem.species, s);
            }

            if (catchItem.keptWeight > 0) {
              s.weight += catchItem.keptWeight;
            }
            if (catchItem.keptNbSpecimen > 0) {
              s.quantity += catchItem.keptNbSpecimen;
            }
            if (catchItem.specimenCaught > 0) {
              s.quantity += catchItem.specimenCaught;
            }

            if (
              !species.includes(catchItem.species) &&
              (s.hasWeight() || s.hasQuantity())
            ) {
              species.push(catchItem.species);
            }
            if (catchItem.keptWeight > 0 && catchItem.species) {
              const currentWeight =
                this.mapMaxWeight.get(catchItem.species) ?? 0;
              this.mapMaxWeight.set(
                catchItem.species,
                catchItem.keptWeight + currentWeight
              );
            }
          });
        });
      });

      this.catchSpecies = this.buildOptions(species, this.systemsLists.species);
    },
    closeUsage(index) {
      Vue.set(this.refreshByIndex, index, this.refreshByIndex[index] + 1);
      this.closeObject({
        value: this.personalUses,
        type: `${this.type}-${index}`,
        index: index,
        vm: this
      });
    },
    async addUsage() {
      ++this.showErrorData;
      const vm = this;
      await vm.$nextTick(); // TODO: ces deux appels à $nextTick indiquent un problème de timing ;-)
      await vm.$nextTick();
      const lastIndex = this.personalUses.length - 1;
      const errorsFieldsNames = keysMatchingValue(
        this.stateErrors,
        true,
        `${this.type}-${lastIndex}`
      );
      if (errorsFieldsNames.length > 0) {
        scrollToTopField(errorsFieldsNames);
        return;
      }
      let personalUse = new PersonalUseModel();
      if (this.hasPersonalUseProductForm !== this.$const.BLOCKED) {
        personalUse.productForm = this.$const.FORM_ROUND;
      }
      this.personalUses.push(personalUse);
      this.refreshByIndex.push(0);
      this.updateHasTrapSize(this.openTrip.efforts);
    },
    removeTrapSizeGhostData() {
      if (!this.hasTrapSize) {
        for (let i = 0; i < this.personalUses.length; i++) {
          delete this.personalUses[i].size;
        }
      }
    },
    // TODO: BL - Most of the complexity of the methods in this components could
    // literally go away by creating a separate component to handle one PCONS.
    updateHasTrapSize(efforts) {
      this.hasTrapSize = false;
      for (let effort of efforts) {
        const catchSpecies = this.extractCatchSpeciesFromEffort(effort);
        for (let catchSpecie of catchSpecies) {
          this.hasTrapSize ||=
            this.getPropertyValue(
              "personalUseSpeciesSizes",
              {
                subforms: this.subform,
                targets: effort.target,
                catchSpecies: catchSpecie
              },
              []
            ).length > 0;
          if (this.hasTrapSize) {
            this.removeTrapSizeGhostData();
            return;
          }
        }
      }
    },
    extractCatchSpeciesFromEffort(effort) {
      const uniqueSpecies = [];
      for (const tow of effort.tows) {
        for (const catchData of tow.catches) {
          if (!uniqueSpecies.includes(catchData.species)) {
            uniqueSpecies.push(catchData.species);
          }
        }
      }
      return uniqueSpecies;
    },
    removeUsage() {
      this.personalUses.pop();
    },
    addError(error, index) {
      // this.addErrorsToState({ page: this.type, errors: error }); uncomment if we want to block closing usage if any usage is invalid
      this.addErrorsToState({ page: `${this.type}-${index}`, errors: error });
      this.addErrorToParent(error);
    },
    trapSizesOptionsBySpecie(specie) {
      if (specie == null) {
        return [{ value: null, text: "---------", disabled: true }];
      }
      const result = this.getPropertyValue("hasMoreOptionsInSize", {
        catchSpecies: specie
      });
      const options = result
        ? this.$t("sizeOptionsLobster.items")
        : this.$t("sizeOptions.items");
      return options;
    },
    // Rule 903: When the two fields (weight and kept) are present (not blocked) at least one must have a value.
    atLeastOneFieldRequired(personal) {
      return this.hasPersonalUseWeight === this.$const.BLOCKED ||
        this.hasPersonalUseNbSpecimens === this.$const.BLOCKED
        ? false
        : personal.weight === null && personal.kept === null;
    }
  },
  mounted() {
    if ("creation" in this.openTrip) {
      this.updateCatchSpecies(this.openTrip.efforts);
      this.updateHasTrapSize(this.openTrip.efforts);
      this.selectDefaultSize();
      this.personalUses = this.openTrip.personnalUses ?? [];
      this.refreshByIndex = Array(this.personalUses.length).fill(0);
    }
  }
};
</script>
