export default class BaseModel {
  constructor() {}
  init(data) {
    for (const entry of Object.entries(data)) this.#buildProp(entry);
  }
  #buildProp([key, value]) {
    if (key in this) {
      this[key] = value;
    }
  }
}
