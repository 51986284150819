<template>
  <footer v-if="isAppInitialized">
    <p class="gen-txtCenter gen-center">{{ $t("footer.text") }}</p>
    <BuildID />
  </footer>
</template>

<script>
import BuildID from "@/components/BuildID.vue";
export default {
  name: "Footer",
  components: {
    BuildID
  },
  computed: {
    isAppInitialized() {
      return this.$store.getters.IS_APP_INITIALIZED;
    }
  }
};
</script>

<style scoped lang="scss">
footer {
  padding: 100px 5px 20px 5px;

  p {
    text-align: center;
    color: #aaa;
    font-size: 0.88em;
    line-height: 1.5;
  }
}
</style>
