<template>
  <div>
    <div class="page modulesPage archive insideApp" id="pageArchive_modules">
      <h1>
        Module
        <span class="moduleName">{{ subscription.module.label }}</span>
      </h1>
      <h2>{{ $t("archivePageHeader") }}</h2>
      <div class="content">
        <div class="topPageMenu">
          <a
            v-html="$t('returnToMyArchives')"
            class="backToPageArchiveLobby"
            @click="clickReturn()"
          />
        </div>
        <FaqManager :faq-data="$t('archive.faqDataNoDatePicker')" />
        <form id="archiveWindowSelector" novalidate="novalidate">
          <fieldset>
            <Select
              v-model="dateSelection"
              :label="$t('archive.period')"
              :options="$t('archive.dateOptions')"
              :required="true"
              :enable-sort="false"
            />
            <br />
            <span v-if="customDate" class="line-block-no-line">
              <p class="leftHalf">
                <span class="label">
                  <span>
                    <label> {{ $t("archive.from") }}</label>
                  </span>
                </span>
                <span class="line-block-no-line noPadding">
                  <input
                    v-model="startDatetime"
                    name="startDatetimeTxt"
                    type="text"
                    class="valid"
                  />
                  <a class="customDateIcon">
                    <b-form-datepicker
                      v-model="startDatetime"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                        weekday: 'short'
                      }"
                      id="datepicker-start"
                      size="sm"
                      boundary="viewport"
                      button-only
                      today-button
                      reset-button
                      close-button
                    />
                  </a>
                </span>
                <span v-if="errorStart" class="error small">
                  {{ errorStart }}
                </span>
              </p>
              <p class="rightHalf">
                <span class="label">
                  <span>
                    <label> {{ $t("archive.until") }}</label>
                  </span>
                </span>
                <span class="line-block-no-line noPadding">
                  <input
                    v-model="endDatetime"
                    name="endDatetimeTxt"
                    type="text"
                    class="valid"
                  />
                  <a class="customDateIcon">
                    <b-form-datepicker
                      v-model="endDatetime"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                        weekday: 'short'
                      }"
                      id="datepicker-end"
                      size="sm"
                      boundary="viewport"
                      button-only
                      today-button
                      reset-button
                      close-button
                    />
                  </a>
                </span>
                <span v-if="errorEnd" class="error small">
                  {{ errorEnd }}
                </span>
              </p>
            </span>
            <fieldset>
              <input
                :value="$t('archive.search')"
                :disabled="!(startDatetime && endDatetime)"
                type="button"
                class="addSuppEntryButton"
                @click="refreshList"
              />
            </fieldset>
          </fieldset>
        </form>
        <table class="hide">
          <tbody class="row-trip-template">
            <tr>
              <td class="sortingDatetime-column sortingDatetime"></td>
              <td class="catchSummary-column catchSummary"></td>
              <td class="actions-btn-column actions-btn">
                <a class="btn-view">Voir le voyage</a>
              </td>
            </tr>
          </tbody>
        </table>
        <h2 class="tableTitle">
          <span>
            <span>
              <span>
                {{ $t("archive.trips") }}
                <span> ({{ displayedTrips.length }}) </span>
              </span>
            </span>
          </span>
        </h2>
        <table class="tableList archive">
          <tbody>
            <tr class="tableHead">
              <th class="column-title">
                {{ $t("date") }}
              </th>
              <th class="column-title">
                {{ $t("archive.keptInfo") }}
              </th>
              <th class="column-title">
                {{ $t("actions") }}
              </th>
            </tr>
            <tr
              v-if="displayedTrips && displayedTrips.length === 0"
              class="empty-list"
            >
              <td class="empty-list archive sortingDatetime">
                ...
              </td>
              <td class="empty-list archive">...</td>
              <td class="empty-list archive right">...</td>
            </tr>
            <tr v-for="(trip, index) in displayedTrips" :key="index">
              <td class="archive sortingDatetime">
                <a>
                  <label class="normal">
                    {{ formatDate(trip["datetime"]) }}
                  </label>
                </a>
              </td>
              <td class="archive">
                <label class="normal">{{ catchValue(trip) }}</label>
              </td>
              <td class="archive right">
                <div class="actions-btn-column actions-btn">
                  <a class="btn-view" @click="viewTrip(trip)">
                    {{ $t("triplist.action.view") + " " + trip.logbookUID }}
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Select from "@/components/Select.vue";
import FaqManager from "@/components/widgets/FaqManager.vue";

export default {
  name: "",
  components: {
    Select,
    FaqManager
  },
  data() {
    return {
      trips: [],
      displayedTrips: [],
      selectTrip: {},
      startDatetime: null,
      endDatetime: null,
      startDate: "",
      endDate: "",
      moduleName: null,
      subscription: null,
      filterKey: {
        year: {},
        month: {},
        day: {}
      },
      selected: {
        full: true,
        year: null,
        month: null,
        day: null
      },
      dateSelection: null,
      firstTime: true,
      faqData: this.$t("archive.faqData"),
      allowFilterDate: false,
      errorStart: null,
      errorEnd: null,
      customDate: false
    };
  },
  computed: {
    ...mapState({
      userProfile: state => state.userProfile,
      subscriptions() {
        return this.$store.getters.SUBSCRIPTIONS;
      },
      isConnectedOnline() {
        return this.$store.getters.IS_APP_CONNECTED_ONLINE;
      }
    })
  },
  watch: {
    subscriptions() {
      const uuid = this.$route.params.uuid;
      for (let i = 0; i < this.subscriptions.length; i++) {
        const subscription = this.subscriptions[i];
        if (subscription.uuid == uuid) {
          this.subscription = subscription;
        }
      }
    },
    dateSelection() {
      let start, end;
      this.customDate = false;
      if (this.dateSelection != "custom") {
        this.startDate = null;
        this.endDate = null;
        this.errorStart = null;
        this.errorEnd = null;
      }
      switch (this.dateSelection) {
        case "today":
          start = this.$t("archive.today");
          end = this.$t("archive.today");
          break;
        case "yesterday":
          start = "-1 " + this.$t("archive.day");
          end = "-1 " + this.$t("archive.day");
          break;
        case "daybeforeyesterday":
          start = "-2 " + this.$t("archive.day");
          end = "-2 " + this.$t("archive.day");
          break;
        case "last30days":
          start = "-30 " + this.$t("archive.day");
          end = this.$t("archive.today");
          break;
        case "last7days":
          start = "-7 " + this.$t("archive.day");
          end = this.$t("archive.today");
          break;
        case "last90days":
          start = "-90 " + this.$t("archive.day");
          end = this.$t("archive.today");
          break;
        case "last2year":
          start = "-730 " + this.$t("archive.day");
          end = this.$t("archive.today");
          break;
        case "lastyear":
          start = "-365 " + this.$t("archive.day");
          end = this.$t("archive.today");
          break;
        case "thisweek":
          start = "0 " + this.$t("archive.week");
          end = "0 " + this.$t("archive.week");
          break;
        case "previousweek":
          start = "-1 " + this.$t("archive.week");
          end = "-1 " + this.$t("archive.week");
          break;
        case "thismonth":
          start = "0 " + this.$t("archive.month");
          end = "0 " + this.$t("archive.month");
          break;
        case "previousmonth":
          start = "-1 " + this.$t("archive.month");
          end = "-1 " + this.$t("archive.month");
          break;
        case "thisyear":
          start = "0 " + this.$t("archive.year");
          end = "0 " + this.$t("archive.year");
          break;
        case "previousyear":
          start = "-1 " + this.$t("archive.year");
          end = "-1 " + this.$t("archive.year");
          break;
        case "custom":
          this.customDate = true;
          break;
      }
      if (this.dateSelection != "custom") {
        this.startDatetime = start;
        this.endDatetime = end;
        if (this.firstTime) {
          this.firstTime = false;
          this.refreshList();
        }
      }
    },
    startDate() {
      if (this.startDate) this.startDatetime = new Date(this.startDate);
    },
    endDate() {
      if (this.endDate) {
        let d = new Date(this.endDate);
        d.setDate(d.getDate() + 1);
        this.endDatetime = d;
      }
    }
  },
  methods: {
    ...mapActions("api", ["getArchives", "getTrip"]),
    catchValue(trip) {
      if (trip.keptWeight > 0) {
        return trip.keptWeight + " kg";
      } else if (trip.keptNbSpecimen > 0) {
        return trip.keptNbSpecimen + " " + this.$t("archive.keptNbSpecimen");
      }
      return 0;
    },
    clickReturn() {
      this.$router.push({
        name: "archiveModuleList"
      });
    },
    onlinePopupError() {
      this.$store.dispatch(
        "alert/error",
        this.$store.getters.GET_CURRENT_ONLINE_ERROR
      );
    },
    refreshList() {
      if (!this.startDatetime || !this.endDatetime) {
        return;
      }
      if (!this.isConnectedOnline) {
        this.onlinePopupError();
        return;
      }
      const vuejs = this;
      vuejs.errorStart = null;
      vuejs.errorEnd = null;
      vuejs.trips = [];
      vuejs.displayedTrips = [];
      vuejs.$nextTick(() => {
        const params = {
          subscriptionUUID: vuejs.subscription.uuid,
          start: vuejs.startDatetime,
          end: vuejs.endDatetime
        };
        vuejs.$store
          .dispatch("showLoadingOverlay")
          .then(function() {
            return vuejs.getArchives(params);
          })
          .then(function(trips) {
            vuejs.trips = [];
            vuejs.filterKey.day = [];
            vuejs.filterKey.month = [];
            vuejs.filterKey.year = [];
            vuejs.selected.full = true;
            vuejs.selected.year = null;
            vuejs.selected.month = null;
            vuejs.selected.day = null;
            for (const trip of trips) {
              vuejs.trips.push(trip);
            }
            vuejs.displayedTrips = vuejs.trips;
          })
          .catch(error => {
            if (error.errorType == "user") {
              //errors in forms/other errors
              vuejs.displayedTrips = [];
              if (error.errors) {
                for (const currentError in error.errors) {
                  if (currentError === "start") {
                    vuejs.errorStart = error.errors[currentError];
                  }
                  if (currentError === "end") {
                    vuejs.errorEnd = error.errors[currentError];
                  }
                }
              }
              // TODO: need an alert if start or end is already flagged ? also, does it need a return
              vuejs.$alertUserApiError(error);
              return;
            }
          })
          .finally(() => vuejs.$store.dispatch("hideLoadingOverlay"));
      });
    },
    viewTrip(trip) {
      if (!this.isConnectedOnline) {
        this.onlinePopupError();
        return;
      }
      const vuejs = this;
      const params = {
        subscriptionUUID: trip.uuid,
        i: trip.i
      };
      vuejs.$store
        .dispatch("showLoadingOverlay")
        .then(() => vuejs.getTrip(params))
        .then(function(data) {
          vuejs.selectTrip = data;
          vuejs.$router.push({
            name: "archivetrip",
            params: {
              uuid: vuejs.subscription.uuid,
              luid: trip["logbookUID"],
              startdate: vuejs.startDatetime,
              enddate: vuejs.endDatetime
            }
          });
        })
        .catch(error => vuejs.$alertUserApiError(error))
        .finally(() => vuejs.$store.dispatch("hideLoadingOverlay"));
    },
    formatDate(date) {
      var d = new Date(date);
      let month = String(d.getMonth() + 1);
      let day = String(d.getDate());
      let year = String(d.getFullYear());
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
  },
  beforeMount() {
    //do something after mounting vue instance
    const uuid = this.$route.params.uuid;

    for (let i = 0; i < this.subscriptions.length; i++) {
      const subscription = this.subscriptions[i];
      if (subscription.uuid == uuid) {
        this.subscription = subscription;
      }
    }
    if (this.$route.params.startdate != null)
      this.startDatetime = this.$route.params.startdate;
    if (this.$route.params.enddate != null) {
      this.endDatetime = this.$route.params.enddate;
      this.firstTime = false;
      this.refreshList();
    } else {
      this.dateSelection = "lastyear";
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === "archivetrip") {
      this.$store.dispatch("selectArchive", this.selectTrip).then(next());
    } else {
      next();
    }
  }
};
</script>
<style lang="scss" scoped>
.normal {
  word-break: break-word;
  font-size: 1em;
  line-height: 1.1em;
  letter-spacing: 0;
  margin-bottom: 0;
  margin-right: 5px;
  &.smallText {
    font-size: 0.8em;
  }
}
.customDateIcon {
  display: flex;
  margin: auto;
  width: 27%;
  @media screen and (min-width: 540px) {
    padding: 0% 3% 2.5% 0%;
  }
  @media screen and (max-width: 539.9px) {
    padding: 0% 3% 7% 0%;
  }
}
</style>
