<template>
  <div name="baitViewer">
    <div
      v-for="(bait, index) in baitUsages"
      :key="'bait-' + index"
      class="towEntriesContainer"
    >
      <div>
        <fieldset class="fieldsetViewTrip">
          <h4 class="level1 viewStep">
            {{ $t("editTrip.baitStep.bait") }}
            {{ index + 1 }}
          </h4>
          <b-row>
            <b-col v-if="bait['datetime']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.baitStep.datetime')"
                :value="$showDate(bait['datetime'])"
                type="text"
              />
            </b-col>
            <b-col v-if="bait['type']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.baitStep.type')"
                :value="bait['type']"
                :options="systemsLists.baitType"
                type="select"
              />
            </b-col>
            <b-col v-if="bait['weight']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.baitStep.weight')"
                :value="bait['weight']"
                unit="weight"
                viewOnly
              />
            </b-col>
            <b-col v-if="bait['condition']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.baitStep.condition')"
                :value="bait['condition']"
                :options="systemsLists.baitCondition"
                type="select"
              />
            </b-col>
            <b-col v-if="bait['origin']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.baitStep.origin')"
                :value="bait['origin']"
                :options="$t('baitsOrigin.items')"
                type="select"
              />
            </b-col>
            <b-col v-if="bait['averageWeightPerGear']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.baitStep.averageWeightPerGear')"
                :value="bait['averageWeightPerGear']"
                unit="weight"
                viewOnly
              />
            </b-col>
            <b-col v-if="bait['remark']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.remarkTitle')"
                :value="bait['remark']"
                type="text"
              />
            </b-col>
            <b-col v-if="bait['closeDatetime']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('dataGroupClosureDate')"
                :value="$showDate(bait['closeDatetime'])"
                type="text"
              />
            </b-col>
          </b-row>
        </fieldset>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import LabelView from "@/components/LabelView.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  name: "BaitViewer",
  components: {
    LabelView,
    UnitConverter
  },
  props: {
    baitUsages: Array
  },
  data: () => ({
    localBaitUssage: []
  }),
  computed: {
    ...mapState({
      subscription: state => state.currentSubscription,
      systemsLists: state => state.systemsLists
    })
  },
  watch: {
    systemsLists() {
      this.$router.go();
    }
  },
  mounted() {
    this.localBaitUssage = this.baitUsages;
    if (
      this.baitUsages[0].category != undefined ||
      this.baitUsages[0].category != null
    ) {
      for (let baitIndex in this.baitUsages) {
        for (let itemIndex in this.baitUsages[baitIndex].category) {
          this.localBaitUssage[baitIndex][itemIndex] = this.baitUsages[
            baitIndex
          ].category[itemIndex];
        }
      }
    }
  }
};
</script>
