<template>
  <div :name="$options.name">
    <h3 class="step-title">
      <span>
        <span>
          <span>{{ $t("editTrip.experimentalStep.title") }}</span>
        </span>
      </span>
    </h3>
  </div>
</template>

<script>
export default {
  name: "ExperimentalDataEditor"
};
</script>
