const getDefaultState = () => {
  return {
    hailOutCallProviders: [{ value: null, text: "---------" }],
    hailInCallProviders: [{ value: null, text: "---------" }],
    operatorsFin: [],
    specieOptions: [{ value: null, text: "---------" }],
    gearTypeOptions: [{ value: null, text: "---------" }],
    trapSizeOptions: [],
    allowedDfoRegion: {},
    fishingAreasOptions: [{ value: null, text: "---------" }],
    effortAreas: [],
    gearLossAreas: {
      minLat: [],
      maxLat: [],
      minLong: [],
      maxLong: []
    },
    contextDfoRegion: null,
    allowedFishingAreas: [
      {
        hasNbGear: false
      }
    ],
    suboperators: [],
    suboperatorsFin: [],
    vessels: [],
    vesselsRN: []
  };
};

export default {
  namespaced: false,
  state: getDefaultState(),
  actions: {},
  getters: {},
  mutations: {}
};
