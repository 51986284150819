/* eslint-disable no-console */

import { register } from "register-service-worker";
import i18n from "./i18n";
import DB from "@/service/db";
import store from "./store/";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      ); // eslint-disable-line no-console
    },
    registered() {
      console.log("Service worker has been registered."); // eslint-disable-line no-console
      var refreshing = false;
      navigator.serviceWorker.addEventListener("controllerchange", function() {
        DB.getItem("userProfile").then(function(data) {
          if (data != null) {
            if (refreshing) return;
            if (store.getters.GET_IN_EDIT_TRIP) {
              store.commit("SET_NEED_REFRESH_WINDOW", true);
              return;
            }
            console.info(i18n.t("newcontentavailable")); // eslint-disable-line no-console
            window.location.reload();
            refreshing = true;
          }
        });
      });
    },
    cached() {
      console.log("Content has been cached for offline use."); // eslint-disable-line no-console
    },
    updatefound() {
      console.log("New content is downloading."); // eslint-disable-line no-console
    },
    updated(reg) {
      console.log("New content is available; please refresh."); // eslint-disable-line no-console
      reg.waiting.postMessage({ type: "SKIP_WAITING" });
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      ); // eslint-disable-line no-console
    },
    error(error) {
      console.error("Error during service worker registration:", error); // eslint-disable-line no-console
    }
  });
}
