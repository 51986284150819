<template>
  <div id="pageSettings_module" class="page insideApp">
    <h1>
      {{ $t("triplist.title", { name: subscription.module.label }) }}
    </h1>
    <h2>
      {{ $t("settings.subtitle") }}
    </h2>
    <div class="content">
      <div class="topPageMenu">
        <a @click="returnClick" v-html="$t('returnToRegister')" />
      </div>
      <form
        @submit.prevent="clickSave"
        autocomplete="off"
        class="moduleSubscriptionForm list"
      >
        <SubformSelector
          :checkError="checkError"
          :refresh="refreshError"
          @regionUpdated="updateRegion"
          @targetUpdated="updateTarget"
          @gearUpdated="updateGear"
          @subformSelected="updateSubform"
        />

        <Operator
          :checkError="checkError"
          :refreshError="refreshError"
          :form="form"
          :refreshForm="refreshForm"
        />

        <CrewEdit
          v-model="crew"
          :hasCrewCount="hasCrewNb !== $const.BLOCKED"
          :refresh="refreshError"
          @error="checkError"
        />

        <Vessel
          :checkError="checkError"
          :refreshError="refreshError"
          :refreshForm="refreshForm"
          :form="form"
        />

        <Licences
          :checkError="checkError"
          :refreshError="refreshError"
          :refreshForm="refreshForm"
          :form="form"
          :setLicenceError="setLicenceError"
          @formUpdated="updateFormFromChild"
          @addLicence="addLicence"
        />

        <Gears
          :checkError="checkError"
          :refreshError="refreshError"
          :form="form"
        />

        <Baits
          :checkError="checkError"
          :refreshError="refreshError"
          :form="form"
        />

        <Ports
          :checkError="checkError"
          :refreshError="refreshError"
          :form="form"
        />

        <Hail
          :checkError="checkError"
          :refreshError="refreshError"
          :form="form"
        />

        <Transfers
          :checkError="checkError"
          :refreshError="refreshError"
          :form="form"
        />

        <GearInformations
          v-if="gearInfoMode"
          :checkError="checkError"
          :refreshError="refreshError"
          :refreshForm="refreshForm"
          @formUpdated="updateFormFromChild"
          @addGearInfo="addGearInfo"
          :form="form"
          :isSetting="true"
        />

        <UnitsOfMeasurement
          :checkError="checkError"
          :refreshError="refreshError"
          :refreshForm="refreshForm"
          :form="form"
        />

        <button
          type="submit"
          class="saveButton"
          :class="!isOnline && 'deactivated'"
        >
          {{ $t("save") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { emptyGearInfo } from "@/utils/utils";

import Baits from "@/components/TripListingSetting/Baits.vue";
import CrewEdit from "@/components/editors/CrewEdit.vue";
import GearInformations from "@/components/TripListingSetting/GearInformations.vue";
import Gears from "@/components/TripListingSetting/Gears.vue";
import Hail from "@/components/TripListingSetting/Hail.vue";
import Licences from "@/components/TripListingSetting/Licences.vue";
import Operator from "@/components/TripListingSetting/Operator.vue";
import Ports from "@/components/TripListingSetting/Ports.vue";
import SubformSelector from "@/components/SubformSelector.vue";
import Transfers from "@/components/TripListingSetting/Transfers.vue";
import UnitsOfMeasurement from "@/components/TripListingSetting/UnitsOfMeasurement.vue";
import Vessel from "@/components/TripListingSetting/Vessel.vue";

const emptyLicence = function() {
  return {
    fishingLicenceNumber: "",
    targetOtherDescription: null,

    // targetSpecie: null, ---> Deprecated, use 'target' instead.
    target: null,
    area: null,
    fishingType: null,
    nbGear: null,
    lgrid: null,
    section: null,
    secteur: null,
    quotas: []
  };
};

export default {
  name: "ModuleSettings",
  components: {
    Baits,
    CrewEdit,
    GearInformations,
    Gears,
    Hail,
    Licences,
    Operator,
    Ports,
    SubformSelector,
    Transfers,
    UnitsOfMeasurement,
    Vessel
  },
  data: function() {
    return {
      firstTrigger: true,
      crew: {
        count: 0,
        members: []
      },
      error: {},
      licenceError: {},
      refreshError: 0,
      referenceArea: null,
      referenceTargetSpecie: null,
      form: {
        subform: null,
        dfoRegion: null,
        subType: null,
        operator: {
          name: "",
          fisherIdentificationNumber: "",
          email: null,
          address: null,
          phoneNumber: null
        },
        vessel: {
          name: "",
          registeredNumber: ""
        },
        carrier: {
          name: null,
          registeredNumber: null
        },
        crew: [],
        crewNb: 1,
        partnership: 39468,
        licences: [],
        arrivalPort: null,
        departurePort: null,
        gears: null,
        trapSize: null,
        meshType: null,
        meshSize: null,
        firstBaitType: null,
        quota: "",
        quotaCutoff: null,
        quotaPeriod: "",
        hailCallCompany: null,
        hailOutCallCompany: null,
        hasTransfer: null,
        defaultDepartureTime: null,
        gearInfos: [],
        unitWeight: null
      }
    };
  },
  watch: {
    "form.subform": function() {
      // LF: this is a hack to avoid the first trigger of the watcher
      if (this.firstTrigger) {
        this.firstTrigger = false;
        return;
      }

      if (this.gearInfoMode && this.form.gearInfos.length === 0) {
        this.form.gearInfos = [];
        this.form.gearInfos.push(emptyGearInfo());
      }
      if (!this.gearInfoMode) {
        this.form.gearInfos = [];
      }
    },
    "form.gears": function() {
      // this.getTrapSize(this.form.gears);
    },
    "crew.count": function(newCrewCount) {
      this.form.crewNb = newCrewCount;
    },
    "crew.members": function(newCrewMembers) {
      this.form.crew = newCrewMembers;
    },
    "form.licences": {
      handler() {
        // this.updateHailData();
        const licence = this.form.licences.at(-1);
        if (licence && licence.area != this.referenceArea) {
          console.debug("area update detected"); // eslint-disable-line no-console
          this.referenceArea = licence.area;
          this.generateOptions(this.propertyContext);
        }
      },
      deep: true
    },
    fishingAreaOptions: {
      immediate: true,
      handler: function() {
        this.generateAreaPreferences();
      }
    }
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapGetters("api", ["isOnline"]),
    ...mapState({
      systemsLists: state => state.systemsLists,
      subscription: state => state.currentSubscription,
      userProfile: state => state.userProfile
    }),
    hasCrewNb() {
      return this.getPropertyValue("hasCrewNb", {
        subforms: this.form.subform
      });
    },
    gearInfoMode() {
      return this.getPropertyValue("gearInfoMode", {
        subforms: this.form.subform
      });
    },
    propertyContext() {
      const context = {};
      if (this.form.subform) context.subforms = this.form.subform;
      if (this.form.dfoRegion) context.regions = this.form.dfoRegion;
      if (this.form.gears) context.gears = this.form.gears;
      if (this.referenceTargetSpecie)
        context.targetSpecies = this.referenceTargetSpecie;

      if (this.form.licences && this.form.licences.length > 0) {
        const licence = this.form.licences[0];
        if (licence.area) context.areas = licence.area;
      }

      return context;
    },
    areas() {
      return this.getPropertyValue("areas", this.propertyContext, []);
    },
    fishingAreaOptions() {
      return [{ value: null, text: "---------" }, ...this.areas];
    }
  },
  methods: {
    ...mapActions([
      "generateOptions",
      "generateSubformOptions",
      "generateSubform",
      "generateVesselData",
      "generateOperatorsData",
      "updateSubStateGeneric"
    ]),
    updateRegion(regionId) {
      if (regionId != this.form.dfoRegion) {
        if (process.env.VUE_APP_DEBUG_SUBFORM) {
          console.debug("dfo region updated", regionId); // eslint-disable-line no-console
        }
        this.form.dfoRegion = regionId;
      }
    },
    updateTarget(targetId) {
      if (targetId != this.referenceTargetSpecie) {
        this.referenceTargetSpecie = targetId;
        if (process.env.VUE_APP_DEBUG_SUBFORM) {
          console.debug("target updated", targetId); // eslint-disable-line no-console
        }
        if (this.form.licences) {
          const licences = Object.values(this.form.licences);
          for (let i = 0; licences && i < licences.length; i++) {
            if (targetId != licences[i].target) {
              licences[i].target = targetId;
            }
          }
        }
        this.generateAreaPreferences();
        this.generateOptions(this.propertyContext);
      }
    },
    updateGear(gearId) {
      if (gearId != this.form.gears) {
        if (process.env.VUE_APP_DEBUG_SUBFORM) {
          console.debug("gear updated", gearId); // eslint-disable-line no-console
        }
        this.form.gears = gearId;
        this.generateAreaPreferences();
        this.generateOptions(this.propertyContext);
      }
    },
    updateSubform(subformId) {
      if (subformId != this.form.subform) {
        if (process.env.VUE_APP_DEBUG_SUBFORM) {
          console.debug("subform updated", subformId); // eslint-disable-line no-console
        }
        this.form.subform = subformId;
        this.generateSubform({ subform: subformId, isSetting: true });
        this.generateAreaPreferences();
        this.generateOptions(this.propertyContext);
      }
    },
    updateFormFromChild(newForm) {
      for (let key in newForm) {
        this.form[key] = newForm[key];
      }
    },
    addGearInfo() {
      let newGearInfo = emptyGearInfo();
      this.form.gearInfos.push(newGearInfo);
    },
    addLicence() {
      let newLicence = emptyLicence();
      newLicence.target = this.referenceTargetSpecie;
      newLicence.area = this.referenceArea;
      this.form.licences.push(newLicence);

      // TODO: Call action to save settings, when we develop offline settings
    },
    checkError(value) {
      this.error = { ...this.error, ...value };
    },
    refreshForm() {
      ++this.refreshError;
    },
    onlinePopupError() {
      this.$store.dispatch(
        "alert/error",
        this.$store.getters.GET_CURRENT_ONLINE_ERROR
      );
    },
    async clickSave(e) {
      // if (!this.isConnectedOnline) {
      //   this.onlinePopupError();
      //   return;
      // }
      e.preventDefault();
      const vm = this;
      vm.refreshForm();
      await vm.$nextTick();
      if (!vm.$hasInputErrorAndScroll()) {
        vm.$store
          .dispatch("showLoadingOverlay")
          .then(() => {
            return vm.$store.dispatch("saveSubscriptionSettings", vm.form);
          })
          .then(function() {
            vm.$store.dispatch(
              "alert/success",
              vm.$i18n.t("message.save_success")
            );
            vm.returnClick();
          })
          .catch(error => vm.$alertUserApiError(error))
          .finally(() => vm.$store.dispatch("hideLoadingOverlay"));
      }
    },
    setLicenceError(value) {
      this.licenceError = { ...this.licenceError, ...value };
      this.error = { ...this.error, ...value };
    },
    async initForm() {
      if (Object.keys(this.subscription.preferences).length === 0) {
        // user without subscription preferences
        if (this.form.licences.length === 0) {
          this.form.operator.fisherIdentificationNumber = this.userProfile.fisherIdentificationNumber;
          this.form.licences.push(emptyLicence());
        }
        if (this.form.gearInfos.length === 0) {
          this.form.gearInfos.push(emptyGearInfo());
        }
        if (this.userProfile.dfoRegion != null) {
          this.form.dfoRegion = this.userProfile.dfoRegion;
          this.generateAreaPreferences();
          this.generateOptions(this.propertyContext); // LF : keep this one ?
        }
        return;
      }
      const preferences = structuredClone(this.subscription.preferences);
      this.form.unitWeight = preferences.unitWeight;
      this.form.subform = preferences.subform;
      this.form.dfoRegion = preferences.dfoRegion;
      this.form.subType = preferences.subType;
      this.form.arrivalPort = preferences.arrivalPort;
      this.form.departurePort = preferences.departurePort;
      this.form.crew = preferences.crew;
      this.form.crewNb = preferences.crewNb;

      this.generateSubform({ subform: this.form.subform, isSetting: true });

      this.form.operator.name = preferences.operator.name;
      this.form.operator.fisherIdentificationNumber =
        preferences.operator.fisherIdentificationNumber;
      this.form.operator.email = preferences.operator.email;
      this.form.operator.address = preferences.operator.address;
      this.form.operator.phoneNumber = preferences.operator.phoneNumber;

      this.form.vessel.name = preferences.vessel.name;
      this.form.vessel.registeredNumber = preferences.vessel.registeredNumber;

      this.form.partnership = preferences.partnership;
      this.form.arrivalPort = preferences.arrivalPort;
      this.form.departurePort = preferences.departurePort;
      this.form.trapSize = preferences.trapSize;
      this.form.meshType = preferences.meshType;
      this.form.meshSize = preferences.meshSize;
      this.form.firstBaitType = preferences.firstBaitType;
      this.form.quota = preferences.quota;
      this.form.quotaCutoff = preferences.quotaCutoff;
      this.form.quotaPeriod = preferences.quotaPeriod;
      this.form.hailCallCompany = preferences.hailCallCompany;
      this.form.hailOutCallCompany = preferences.hailOutCallCompany;
      this.form.hasTransfer = preferences.hasTransfer;

      if (preferences.carrier) {
        this.form.carrier.name = preferences.carrier.name;
        this.form.carrier.registeredNumber =
          preferences.carrier.registeredNumber;
      }

      // gears value get auto selected after dfo region is set if only one, dont overwrite
      if (!this.form.gears) this.form.gears = preferences.gears;

      this.form.licences = [];
      if (preferences.licences) {
        const values = Object.values(preferences.licences);
        for (let i = 0; i < values.length; i++) {
          if (!values[i].quotas) {
            values[i].quotas = [];
          }
          this.form.licences.push(values[i]);
          this.referenceTargetSpecie = values[i].target;
          this.referenceArea = values[i].area;
        }
      }
      if (this.gearInfoMode !== 0) {
        if (preferences.gearInfos) {
          const values = Object.values(preferences.gearInfos);
          for (let i = 0; i < values.length; i++) {
            this.form.gearInfos.push(values[i]);
          }
        } else {
          if (this.form.gearInfos.length === 0) {
            this.form.gearInfos.push(emptyGearInfo());
          }
        }
      }
      if (this.form.licences.length === 0) {
        this.form.operator.fisherIdentificationNumber = this.userProfile.fisherIdentificationNumber;
        this.form.licences.push(emptyLicence());
      }
      this.generateAreaPreferences();
      this.generateOptions(this.propertyContext);
    },
    generateAreaPreferences() {
      // LF: would prefer using an internal variable but we share FishingLicence component
      // TODO: BL - This state is no longer used.
      this.updateSubStateGeneric({
        name: "fishingAreasOptions",
        value: this.fishingAreaOptions
      });
    },
    initCrew() {
      this.crew.count = this.form.crewNb;
      this.crew.members = this.form.crew;
    },
    returnClick() {
      this.$router.push({
        name: "module",
        params: { uuid: this.$route.params.uuid }
      });
      // this.$router.back(); TODO: navigation: dont work when reloading to this position (need two for init/replace)
    }
  },
  beforeMount() {
    this.generateSubformOptions();
    this.generateVesselData();
    this.generateOperatorsData();
    this.initForm();
  },
  mounted() {
    this.initCrew();
  }
};
</script>
