import BaseModel from "./BaseModel.js";

export default class PersonalUseModel extends BaseModel {
  species = null;
  productForm = null;
  remark = null;
  weight = null;
  kept = null;
  usage = null;
  size = null;
  constructor() {
    super();
    // Object.seal(this);
  }
}
