<template>
  <div :name="$options.name">
    <!-- Button shown to users -->
    <button
      class="stepperBtn unprintable"
      style="position:static; float:right"
      @click="print"
      type="button"
    >
      {{ $t("receipt.printReceipt") }}
    </button>
    <!-- Content that will be printed, but not shown to users -->
    <div id="receiptContent" ref="receiptContent" style="display: none;">
      <div :style="styles.invoiceBox">
        <table :style="styles.table" cellpadding="0" cellspacing="0">
          <tr :style="styles.topRow">
            <td colspan="2" :style="styles.row1">
              <table :style="styles.table">
                <tr :style="styles.titleRow">
                  <td :style="styles.cell1">
                    <img
                      height="41"
                      width="128"
                      src="/img/icons/jobel-logo-transparent-cropped-512.png"
                    /><br />
                    v{{ jobelVersion }}
                  </td>
                  <td :style="styles.cell2">
                    {{ $t("receipt.label") }} {{ payment.invoiceNumber }}<br />
                    {{ $showDate(payment.orderDatetime) }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td colspan="2" :style="styles.row2">
              <table :style="styles.table">
                <tr>
                  <!-- Le format exact d'une adresse est défini par Postes Canada : https://www.canadapost-postescanada.ca/scp/fr/soutien/bc/entreprises/exactitude-adresses/ladressage-exact#:~:text=Inscrivez%20le%20num%C3%A9ro%20d'unit%C3%A9,un%20trait%20d'union. -->
                  <td :style="styles.cell3">
                    RPPSG<br />
                    201-31&nbsp;rue&nbsp;Commerciale&nbsp;O<br />
                    Chandler&nbsp;QC&nbsp;&nbsp;G0C&nbsp;1K0<br />
                    administration@rppsg.ca<br />
                  </td>

                  <td :style="styles.cell4">
                    {{ userProfile.userfullname }}<br />
                    {{ $t("fin.short") }} {{ userProfile.username }}<br />
                    {{ userProfile.address }}, {{ province }}<br />
                    {{ userProfile.email }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>

          <tr :style="styles.heading">
            <td>Item</td>
            <td :style="styles.textAlignRight">{{ $t("receipt.price") }}</td>
          </tr>

          <tr :style="styles.item">
            <td>{{ payment.description }}</td>
            <td :style="styles.textAlignRight">
              {{ payment.amount | amount }}
            </td>
          </tr>

          <tr :style="styles.lastItem">
            <td>{{ $t("receipt.taxes") }}</td>
            <td :style="styles.textAlignRight">{{ payment.taxes | amount }}</td>
          </tr>

          <tr>
            <td></td>
            <td :style="styles.total">{{ payment.total | amount }}</td>
          </tr>

          <tr :style="styles.footing">
            <td colspan="2">
              {{ $t("receipt.gst") }} 131846164 RT<br />
              {{ $t("receipt.qst") }} 1006368928 TQ 0001
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- Required for printing without having to create a new window/tab -->
    <iframe
      :srcdoc="content"
      title="Receipt to print"
      id="receiptprint"
      ref="receiptprint"
      style="display: none; position: absolute; top:0; width:100%"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "ReceiptPrinter",
  components: {},
  filters: {
    amount(value) {
      return value.toFixed(2) + " $";
    }
  },
  props: {
    showErrorData: Number,
    addErrorToParent: Function,
    payment: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {
      content: "",
      jobelVersion: process.env.VUE_APP_VERSION,
      styles: {
        invoiceBox: {
          padding: "30px",
          border: "1px solid rgb(238, 238, 238)",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.15)",
          fontSize: "1em",
          lineHeight: "1.5em",
          color: "rgb(85, 85, 85)"
        },
        table: {
          width: "100%",
          padding: "5px"
        },
        titleRow: {
          fontSize: "0.88em",
          lineHeight: "1em",
          color: "rgb(51, 51, 51)"
        },
        topRow: { paddingBottom: "20px" },
        heading: {
          background: "rgb(238, 238, 238)",
          borderBottom: "1px solid rgb(221, 221, 221)",
          fontWeight: "bold"
        },
        item: { borderBottom: "1px solid rgb(238, 238, 238)" },
        lastItem: { borderBottom: "none" },
        total: {
          borderTop: "2px solid rgb(200, 200, 200)",
          fontWeight: "bold",
          textAlign: "right"
        },
        footing: {
          fontSize: "0.6rem",
          lineHeight: "0.6rem",
          color: "grey"
        },
        row1: {
          margin: "auto",
          paddingTop: "5px",
          paddingBottom: "20px",
          paddingLeft: 0,
          paddingRight: 0,
          width: "100%"
        },
        row2: {
          fontSize: "0.88em",
          lineHeight: "1em",
          paddingTop: "5px",
          paddingBottom: "40px",
          paddingLeft: 0,
          paddingRight: 0,
          verticalAlign: "top"
        },
        noPadding: {
          paddingLeft: 0,
          paddingRight: 0
        },
        textAlignRight: { textAlign: "right" },
        cell1: {
          margin: "auto",
          paddingTop: "5px",
          paddingLeft: 0,
          paddingRight: 0,
          width: "60%"
        },
        cell2: {
          margin: "auto",
          paddingTop: "5px",
          paddingLeft: 0,
          paddingRight: 0,
          textAlign: "right",
          verticalAlign: "top",
          width: "40%"
        },
        cell3: {
          fontSize: "0.88em",
          lineHeight: "1em",
          paddingTop: "5px",
          paddingLeft: 0,
          paddingRight: 0,
          verticalAlign: "top"
        },
        cell4: {
          fontSize: "0.88em",
          lineHeight: "1em",
          paddingTop: "5px",
          paddingLeft: 0,
          paddingRight: 0,
          textAlign: "right",
          verticalAlign: "top"
        }
      }
    };
  },
  computed: {
    ...mapState({
      userProfile: state => state.userProfile
    }),
    province() {
      const province = this.$t("province.items").find(
        item => item.value === this.userProfile.province
      );
      return province ? province.short : "";
    }
  },
  mounted() {
    this.content = this.$refs.receiptContent.innerHTML;
  },
  methods: {
    print() {
      this.$refs.receiptprint.focus();
      this.$refs.receiptprint.contentWindow.print();
    }
  }
};
</script>
