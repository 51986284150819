<template>
  <fieldset class="fieldsetDetailsBorder">
    <h4 class="level2">
      {{ $t("editTrip.lastStep.details") }}
    </h4>
    <fieldset v-for="(detail, index) in details" :key="'hailInDetail-' + index">
      <TowSelector
        v-if="hasHailInDetailTowNum"
        v-model="detail['towNumIdx']"
        :efforts="openTrip.efforts"
        :refeshError="showErrorData"
        :required="hasHailInDetailTowNum === $const.MANDATORY"
        @error="addError"
      />
      <GenericInput
        v-model="detail['remark']"
        :label="$t('editTrip.remark')"
        :maxlength="2000"
        :refeshError="showErrorData"
        :required="false"
        type="text"
        @error="addError"
      />
    </fieldset>
    <fieldset class="suppEntryButtonWrapper removeBottomPadding">
      <p>
        <input
          v-if="details && details !== null && details.length > 0"
          @click="removeDetail()"
          class="removeSuppEntryButton"
          type="button"
          :value="$t('catch.removeDetail')"
        />
        <input
          @click="addDetail()"
          class="addSuppEntryButton"
          type="button"
          :class="details.length > 0 ? 'removeTopMargin' : null"
          :value="$t('catch.addDetail')"
        />
      </p>
    </fieldset>
  </fieldset>
</template>
<script>
import TowSelector from "@/components/widgets/TowSelector.vue";
import GenericInput from "@/components/GenericInput.vue";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    GenericInput,
    TowSelector
  },
  props: {
    showErrorDataParent: {
      type: Number,
      required: true
    },
    addErrorToParent: {
      type: Function,
      required: true
    },
    details: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      error: {},
      showErrorData: 0,
      type: "hailInDetail"
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      subform: state => state.currentOpenTrip.subform
    }),
    hasHailInDetailTowNum() {
      return this.getPropertyValue("hasHailInDetailTowNum", {
        subforms: this.subform
      });
    }
  },
  watch: {
    showErrorDataParent: function() {
      this.showErrorData++;
    }
  },
  methods: {
    ...mapActions(["addErrorsToState"]),
    addDetail() {
      const vm = this;
      vm.showErrorData++;
      vm.$nextTick(() => {
        if (!vm.$hasInputErrorAndScroll("error")) {
          vm.$nextTick(() => {
            if (!vm.details) {
              return;
            }

            vm.details.push({
              remark: null,
              towNumIdx: null
            });
          });
        }
      });
    },
    removeDetail() {
      const vm = this;
      vm.showErrorData++;
      vm.details.pop();
    },
    addError(error) {
      this.error = { ...this.error, ...error };
      this.addErrorToParent(this.error);
      this.$emit("error", this.error);
    }
  },
  mounted() {}
};
</script>
