<template>
  <fieldset :name="'Licence ' + (index + 1)">
    <h4 v-if="multi" class="level2">
      {{ $t("section.licence") }}&nbsp;{{ index + 1 }}
    </h4>
    <GenericInput
      v-model.trim="licence.fishingLicenceNumber"
      :label="$t('fields.fishingLicence')"
      :required="true"
      :minlength="4"
      :maxlength="$const.LIC_NO_MAX_LENGTH"
      :forceErrorMsg="uniqueLicenceNumber(licence.fishingLicenceNumber)"
      :refeshError="refeshError"
      :key="index"
      @error="checkError"
      type="text"
    />
    <Select
      v-model="licence.target"
      :label="$t('fields.targetSpecie')"
      :options="targetSpecies"
      :required="true"
      :refeshError="refeshError"
      @error="checkError"
    />
    <GenericInput
      v-if="hasTargetOtherSpeciesDescription"
      v-model.trim="licence.targetOtherDescription"
      :label="$t('fields.targetOtherDescription')"
      :maxlength="50"
      :required="hasTargetOtherSpeciesDescription === $const.MANDATORY"
      :refeshError="refeshError"
      @error="checkError"
      type="text"
    />
    <Select
      v-if="hasEffortArea"
      v-model="licence.area"
      :label="$t('fields.area')"
      :options="fishingAreaOptions"
      :required="hasEffortArea === $const.MANDATORY"
      :refeshError="refeshError"
      @error="checkError"
    />
    <LobsterGrid
      v-if="hasLobsterGrid"
      v-model="licence.lgrid"
      :area="licence.area"
      :refresh="refeshError"
      :required="hasLobsterGrid === $const.MANDATORY"
      @error="checkError"
    />
    <Select
      v-if="hasFishingType"
      v-model="licence.fishingType"
      :label="$t('fields.fishingType')"
      :options="fishingTypeOptions"
      :required="hasFishingType === $const.MANDATORY"
      :refeshError="refeshError"
      @error="checkError"
    />
    <Select
      v-if="hasStatSection"
      v-model="licence.section"
      :label="$t('editTrip.effortStep.ssSection')"
      :options="statSectionOptions"
      :required="hasStatSection === $const.MANDATORY"
      :refeshError="refeshError"
      @error="checkError"
    />
    <Select
      v-if="hasEffortDetailPacStatArea"
      v-model="licence.secteur"
      :label="$t('editTrip.effortStep.statSecteur')"
      :options="pacAreas"
      :required="hasEffortDetailPacStatArea === $const.MANDATORY"
      :refeshError="refeshError"
      @error="checkError"
    />
    <IntegerInput
      v-if="hasTotalNbGears"
      v-model="licence.nbGear"
      :label="$t('fields.nbGears')"
      :required="hasTotalNbGears === $const.MANDATORY"
      :min="1"
      :max="maxTotalNbGears"
      :refeshError="refeshError"
      @error="checkError"
    />
    <UnitConverter
      v-if="hasQuota"
      v-model="quota"
      :label="$t('fields.quota')"
      :required="hasQuota === $const.MANDATORY"
      :min="0"
      :max="9999999999"
      :refresh="refeshError"
      @error="checkError"
      unit="weight"
    />
  </fieldset>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import LobsterGrid from "@/components/LobsterGrid.vue";
import Select from "@/components/Select.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  name: "FishingLicence",
  components: {
    GenericInput,
    IntegerInput,
    LobsterGrid,
    Select,
    UnitConverter
  },
  props: [
    "licence",
    "index",
    "multi",
    "refeshError",
    "licences",
    "subform",
    "dfoRegion"
  ],
  data() {
    return {
      quota: null,
      licenceError: {}
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue", "buildOptions"]),
    ...mapState({
      systemsLists: state => state.systemsLists,
      subscription: state => state.currentSubscription,
      targetSpecies: state => state.editTripSubform.targetSpecies
    }),
    hasEffortFishingType() {
      return this.getPropertyValue("hasEffortFishingType", {
        subforms: this.subform
      });
    },
    hasTripFishingType() {
      return this.getPropertyValue("hasTripFishingType", {
        subforms: this.subform
      });
    },
    hasTargetOtherSpeciesDescription() {
      return this.getPropertyValue("hasTargetOtherSpeciesDescription", {
        subforms: this.subform,
        targetSpecies: this.licence.target
      });
    },
    hasLobsterGrid() {
      return this.getPropertyValue("hasLobsterGrid", {
        subforms: this.subform,
        areas: this.licence.area
      });
    },
    hasTotalNbGears() {
      return this.getPropertyValue("hasTotalNbGears");
    },
    maxTotalNbGears() {
      return this.getPropertyValue("maxTotalNbGears", {}, 800);
    },
    hasEffortArea() {
      return this.getPropertyValue("hasEffortArea", {
        subforms: this.subform,
        targetSpecies: this.licence.target
      });
    },
    areas() {
      return this.getPropertyValue(
        "areas",
        {
          subforms: this.subform,
          regions: this.dfoRegion,
          targetSpecies: this.licence.target
        },
        []
      );
    },
    fishingAreaOptions() {
      return [
        {
          value: null,
          text: "---------",
          disabled: this.hasEffortArea === this.$const.MANDATORY
        },
        ...this.areas
      ];
    },
    hasEffortDetailPacStatArea() {
      return this.getPropertyValue("hasEffortDetailPacStatArea", {
        subforms: this.subform
      });
    },
    pacAreas() {
      return this.getPropertyValue(
        "pacAreas",
        {
          forms: this.subscription.module.form,
          subforms: this.subform,
          areas: this.licence.area
        },
        this.systemsLists.pareas
      );
    },
    hasStatSection() {
      return this.getPropertyValue("hasStatSection", {
        subforms: this.subform,
        areas: this.licence.area
      });
    },
    statSections() {
      return this.getPropertyValue(
        "statSections",
        { subforms: this.subform, areas: this.licence.area },
        []
      );
    },
    statSectionOptions() {
      return this.buildOptions(
        this.statSections,
        this.systemsLists["ssSections"],
        this.hasStatSection === this.$const.MANDATORY
      );
    },
    hasQuota() {
      return this.getPropertyValue("hasQuota");
    },
    hasFishingType() {
      let hasFishingType = this.$const.BLOCKED;

      if (
        this.hasTripFishingType === this.$const.MANDATORY ||
        this.hasEffortFishingType === this.$const.MANDATORY
      )
        hasFishingType = this.$const.MANDATORY;
      else if (
        this.hasTripFishingType === this.$const.OPTIONAL ||
        this.hasEffortFishingType === this.$const.OPTIONAL
      )
        hasFishingType = this.$const.OPTIONAL;

      return hasFishingType;
    },
    fishingTypes() {
      return this.getPropertyValue(
        "fishingTypes",
        { forms: this.subscription.module.form },
        []
      );
    },
    fishingTypeOptions() {
      if (this.fishingTypes.length > 0) {
        return this.buildOptions(
          this.fishingTypes,
          this.systemsLists.fishingType,
          this.hasFishingType === this.$const.MANDATORY
        );
      } else {
        return [
          {
            value: null,
            text: "---------",
            disabled: this.hasFishingType === this.$const.MANDATORY
          },
          ...this.systemsLists.fishingType
        ];
      }
    },
    quotaDates() {
      const now = Date.now();
      const date = new Date(now);
      const year = date.getFullYear();
      const start = new Date(year, 0, 1);
      const end = new Date(year + 1, 0, 1);
      return {
        start: start.toISOString(),
        end: end.toISOString()
      };
    }
  },
  watch: {
    quota(newWeight) {
      if (newWeight != null) {
        this.licence.quotas = [{ ...this.quotaDates, weight: newWeight }];
      } else {
        this.licence.quotas = [];
      }
    },
    hasTargetOtherSpeciesDescription: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.licence.targetOtherDescription;
        }
      }
    },
    fishingAreaOptions: {
      immediate: true,
      handler: function(options) {
        if (options.length < 2) {
          delete this.licence.area;
        } else if (options.length === 2) {
          this.licence.area = options[1];
        }
      }
    },
    hasFishingType: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.licence.fishingType;
        }
      }
    },
    hasLobsterGrid: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.licence.lgrid;
        }
      }
    },
    hasStatSection: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.licence.section;
        }
      }
    },
    hasEffortDetailPacStatArea: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          delete this.licence.secteur;
        }
      }
    },
    hasQuota: {
      immediate: true,
      handler: function(state) {
        if (state === this.$const.BLOCKED) {
          this.licence.quotas = [];
        }
      }
    }
  },
  beforeMount() {
    // Initialize the quota, if present
    if (this.licence.quotas) {
      if (this.licence.quotas.length > 0) {
        this.quota = this.licence.quotas[0].weight;
      }
    }
  },
  mounted() {
    // This field (licence.targetSpecie) is deprecated and has been replaced by licence.target.
    if (this.licence.targetSpecie) {
      delete this.licence.targetSpecie;
    }
  },
  methods: {
    checkError(value) {
      this.licenceError = { ...this.licenceError, ...value };
      this.$emit("licenceError", this.licenceError); // averti le parent pour empecher la sauvegarde
    },
    uniqueLicenceNumber(licenceNumber) {
      // If, by filtering the list of licences on the specified
      // licence number, we obtain an array of exactly one element,
      // then we know that the licence number is unique.
      if (!this.licences) {
        return undefined;
      }
      const licencesByNumber = this.licences.filter(licence => {
        return licence.fishingLicenceNumber == licenceNumber;
      });
      if (licencesByNumber.length === 1) {
        return undefined;
      }
      // Otherwise, we return an error message.
      return this.$t("fields.licenceError");
    }
  }
};
</script>
