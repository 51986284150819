<template>
  <div id="">
    <b-overlay
      rounded="sm"
      blur="none"
      :key="'inactivity' + inactivity['closeDatetime']"
    >
      <fieldset
        v-for="(detail, index) in inactivity.details"
        :key="'detail-' + index"
        class="tow"
      >
        <h4 class="level1">
          {{ $t("editTrip.inactivityStep.period") }}&nbsp;{{ index + 1 }}
          <span
            v-b-modal="'modal-detail-remark' + index"
            class="add-remark-btn-title"
            v-show="inactivity['closeDatetime'] === undefined"
          ></span>
          <Popup
            v-model="detail['remark']"
            :title-label="$t('editTrip.remarkTitle')"
            :maxlength="2000"
            :modalName="'modal-detail-remark' + index"
          />
        </h4>
        <fieldset :disabled="inactivity['closeDatetime'] !== undefined">
          <GenericInput
            :label="$t('editTrip.effortStep.licenceNumber')"
            v-model.trim="detail['licenceNumber']"
            type="datalist"
            :options="operatorsFin"
            :otherValue="true"
            :required="true"
            :minlength="4"
            :maxlength="$const.LIC_NO_MAX_LENGTH"
            @error="checkError"
            :refeshError="childRefresh"
            :key="'inactivity-licence-' + index"
          />
          <br />
          <DateInput
            :label="$t('editTrip.inactivityStep.start')"
            v-model="detail['start']"
            :required="true"
            :autodatetime="true"
            @error="checkError"
            :refeshError="childRefresh"
          />
          <DateInput
            :label="$t('editTrip.inactivityStep.end')"
            v-model="detail['end']"
            :required="true"
            :autodatetime="true"
            @error="checkError"
            :refeshError="childRefresh"
            :minDates="[
              {
                value: detail['start'],
                text: $t('editTrip.inactivityStep.afterStartDate')
              }
            ]"
          />
          <GenericInput
            :label="$t('editTrip.inactivityStep.reason')"
            v-model="detail['reason']"
            type="text"
            :maxlength="2000"
            :required="true"
            @error="checkError"
            :refeshError="childRefresh"
          />
        </fieldset>
      </fieldset>
      <fieldset class="suppEntryButtonWrapper">
        <p>
          <input
            v-if="
              inactivity.details &&
                inactivity.details !== null &&
                inactivity.details.length > 1 &&
                !checkCloseDate
            "
            @click="removeReport()"
            class="removeSuppEntryButton"
            type="button"
            :value="
              $t('editTrip.inactivityStep.removeDetail') +
                String(inactivity.details.length)
            "
          />
          <input
            v-if="!checkCloseDate"
            @click="addReport()"
            class="addSuppEntryButton"
            type="button"
            :value="$t('editTrip.inactivityStep.addDetail')"
          />
        </p>
      </fieldset>
    </b-overlay>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import GenericInput from "@/components/GenericInput.vue";
import DateInput from "@/components/widgets/DateInput.vue";
import Popup from "@/components/widgets/Popup.vue";
let emptyInactivity = function() {
  return {
    details: []
  };
};
export default {
  name: "InactivityForm",
  components: {
    GenericInput,
    DateInput,
    Popup
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    refresh: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    inactivity: emptyInactivity(),
    error: {},
    childRefresh: 0,
    firstTime: false
  }),
  watch: {
    inactivity: {
      deep: true,
      handler: function() {
        if (!this.firstTime) {
          this.$emit("input", this.inactivity);
        } else {
          this.firstTime = false;
        }
      }
    },
    value: {
      deep: true,
      handler: function() {
        if (this.value && this.value.details) {
          this.inactivity = this.value;
        }
      }
    },
    refresh: function() {
      this.childRefresh++;
    }
  },
  computed: {
    ...mapState({
      operatorsFin: state => state.editTripSubform.operatorsFin
    }),
    checkCloseDate() {
      if (this.inactivity && this.inactivity !== null) {
        if (
          this.inactivity.closeDatetime &&
          this.inactivity.closeDatetime !== null
        ) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    removeReport() {
      this.inactivity["details"] = this.inactivity["details"].slice(0, -1);
      if (this.inactivity["details"].length === 0) {
        this.inactivity = emptyInactivity();
        this.$emit("clear-data");
      }
      this.inactivity = JSON.parse(JSON.stringify(this.inactivity));
    },
    addReport() {
      const vm = this;
      this.childRefresh++;
      vm.$nextTick(() => {
        if (!vm.$hasInputErrorAndScroll("error")) {
          const now = new Date();
          this.inactivity["details"].push({
            start: null,
            end: null,
            licenceNumber:
              this.operatorsFin && this.operatorsFin.length === 1
                ? this.operatorsFin[0].text
                : null,
            reason: null,
            creation: now.toISOString()
          });

          if (this.inactivity["details"].length === 1) {
            this.inactivity["creation"] = now.toISOString();
            this.inactivity["reportUID"] = this.$makeAlphaId(6);
          }
          this.inactivity = JSON.parse(JSON.stringify(this.inactivity));
        }
      });
    },
    checkError(error) {
      this.error = { ...this.error, ...error };
    }
  },
  mounted() {
    //do something after mounting vue instance
    this.inactivity = { ...this.inactivity, ...this.value };

    if (this.inactivity?.details?.length === 0) {
      this.addReport();
    }
  }
};
</script>
