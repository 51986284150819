<template>
  <div name="towInfoViewer">
    <fieldset class="fieldsetViewTrip">
      <h4 class="level3">
        {{ $t("editTrip.effortStep.deploymentInfos") }}
      </h4>
      <b-row>
        <b-col v-if="tow['remark']" cols="12">
          <LabelView
            :label="$t('editTrip.remarkTitle')"
            :value="tow['remark']"
            type="text"
          />
        </b-col>
        <b-col v-if="tow.linesCount" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.nbLines')"
            :value="tow.linesCount"
            type="text"
          />
        </b-col>
        <b-col v-if="tow.strikesCount" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.nbStrikes')"
            :value="tow.strikesCount"
            type="text"
          />
        </b-col>
        <b-col v-if="tow.buoysCount" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.nbBuoys')"
            :value="tow.buoysCount"
            type="text"
          />
        </b-col>
        <b-col v-if="tow.airTemp" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.airTemp')"
            :value="tow.airTemp"
            unit="temperature"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow.waterSurfaceTemp" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.waterSurfaceTemp')"
            :value="tow.waterSurfaceTemp"
            unit="temperature"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow.baitType" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.baitType')"
            :value="tow.baitType"
            :options="systemsLists.baitType"
            type="select"
          />
        </b-col>
        <b-col v-if="tow.baitCategory" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.baitCategory')"
            :value="tow.baitCategory"
            :options="systemsLists.baitCategory"
            type="select"
          />
        </b-col>
        <b-col v-if="tow.agglomerationNumber" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.agglomeration')"
            :value="tow.agglomerationNumber"
            type="text"
          />
        </b-col>
        <b-col v-if="tow['secteur']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.statSecteur')"
            :value="tow['secteur']"
            :options="systemsLists.pareas"
            type="select"
          />
        </b-col>
        <b-col v-if="tow['soakDuration']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.soakDurationInDays')"
            :value="formatDuration(tow['soakDuration'])"
            type="text"
          />
        </b-col>
        <b-col v-if="tow['totalGearLength']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.gearLength')"
            :value="tow['totalGearLength']"
            unit="m"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['averageGearDepth']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.averageGearDepth')"
            :value="tow['averageGearDepth']"
            unit="depth"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['minimumGearDepth']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.minGearDepth')"
            :value="tow['minimumGearDepth']"
            unit="depth"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['maximumGearDepth']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.maxGearDepth')"
            :value="tow['maximumGearDepth']"
            unit="depth"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['averageTowSpeed']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.averageTowSpeed')"
            :value="tow['averageTowSpeed']"
            extra=" kn"
            type="text"
          />
        </b-col>
        <b-col v-if="tow['distance']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.distanceTravelled')"
            :value="tow['distance']"
            unit="m"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['firstCount']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.firstCalculatedWeigth')"
            :value="tow['firstCount']"
            type="text"
          />
        </b-col>
        <b-col v-if="tow.ssSection" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.ssSection')"
            :value="tow.ssSection"
            :options="systemsLists.ssSections"
            type="select"
          />
        </b-col>
        <b-col v-if="tow.trapSize" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.trapSize')"
            :value="tow.trapSize"
            :options="systemsLists.trapSizes"
            type="select"
          />
        </b-col>
        <b-col v-if="tow['nbGearHauled']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.nbGearHauled')"
            :value="tow['nbGearHauled']"
            type="text"
          />
        </b-col>
        <b-col v-if="tow['nbGearLost']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.nbGearLost')"
            :value="tow['nbGearLost']"
            type="text"
          />
        </b-col>
        <b-col v-if="tow['protectedBait']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('fields.hasBaitProtected')"
            :value="tow['protectedBait']"
            :options="systemsLists.response"
            type="select"
          />
        </b-col>
        <b-col v-if="tow['averageBottomDepth']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.averageBottomDepth')"
            :value="tow['averageBottomDepth']"
            unit="depth"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['spotterPlane']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.hasSpotterPlane')"
            :value="tow['spotterPlane']"
            :options="systemsLists.response"
            type="select"
          />
        </b-col>
        <b-col v-if="tow['fishingMethod']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.fishingMethod')"
            :value="tow['fishingMethod']"
            :options="systemsLists.fishingMethod"
            type="select"
          />
        </b-col>
        <b-col v-if="tow['riggedPartLength']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.riggedPartLength')"
            :value="tow['riggedPartLength']"
            unit="m"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['skatesCount']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.skatesCount')"
            :value="tow['skatesCount']"
            type="text"
          />
        </b-col>
        <b-col v-if="tow['fishSite']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.fishSite')"
            :value="tow['fishSite']"
            type="text"
          />
        </b-col>
        <b-col v-if="tow['targetDepth']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.targetDepth')"
            :value="tow['targetDepth']"
            unit="depth"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['warpLength']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.warpLength')"
            :value="tow['warpLength']"
            unit="m"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['wingSpread']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.wingSpread')"
            :value="tow['wingSpread']"
            unit="m"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['doorSpread']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.doorSpread')"
            :value="tow['doorSpread']"
            unit="m"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['optimalDepth']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.optimalDepth')"
            :value="tow['optimalDepth']"
            unit="depth"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['meshSize']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.meshSize')"
            :value="tow['meshSize']"
            unit="mm"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['startGearDepth']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.startGearDepth')"
            :value="tow['startGearDepth']"
            unit="depth"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['endGearDepth']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.endGearDepth')"
            :value="tow['endGearDepth']"
            unit="depth"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow.hooksCount" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.nbHooks')"
            :value="tow.hooksCount"
            type="text"
          />
        </b-col>
        <b-col v-if="tow['hookLostCount']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.hookLostCount')"
            :value="tow['hookLostCount']"
            type="text"
          />
        </b-col>
        <b-col v-if="tow['hookType']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.hookType')"
            :value="tow['hookType']"
            :options="systemsLists.hookType"
            type="select"
          />
        </b-col>
        <b-col v-if="tow['hookSize']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.hookSize')"
            :value="tow['hookSize']"
            :options="systemsLists.hookSize"
            type="select"
          />
        </b-col>
        <b-col v-if="tow['averageGearDistance']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.averageGearDistance')"
            :value="tow['averageGearDistance']"
            unit="m"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['middleGearDepth']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.middleGearDepth')"
            :value="tow['middleGearDepth']"
            unit="depth"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['gearDepthCategory']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.gearDepthCategory')"
            :value="tow['gearDepthCategory']"
            :options="systemsLists.waterDepthCategories"
            type="select"
          />
        </b-col>
        <b-col v-if="tow['verticalSpread']" lg="4" sm="6" cols="12">
          <UnitConverter
            :label="$t('editTrip.effortStep.verticalSpread')"
            :value="tow['verticalSpread']"
            unit="m"
            viewOnly
          />
        </b-col>
        <b-col v-if="tow['potentialSiteWeight']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.potentialSiteWeight')"
            :value="tow['potentialSiteWeight']"
            type="text"
          />
        </b-col>
        <b-col v-if="tow['releaseCause']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.releaseCause')"
            :value="tow['releaseCause']"
            type="text"
          />
        </b-col>
        <b-col v-if="tow['weatherConditions']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.weatherConditions')"
            :value="tow['weatherConditions']"
            type="select"
            :options="systemsLists.weatherConditions"
          />
        </b-col>
        <b-col v-if="tow['populationDensity']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.populationDensity')"
            :value="tow['populationDensity']"
            type="select"
            :options="systemsLists.populationDensity"
          />
        </b-col>
        <b-col v-if="tow['speciesSize']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.speciesSize')"
            :value="tow['speciesSize']"
            type="select"
            :options="systemsLists.productSize"
          />
        </b-col>
        <b-col v-if="tow['spawningActivity']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.spawningActivity')"
            :value="tow['spawningActivity']"
            :options="systemsLists.response"
            type="select"
          />
        </b-col>
        <b-col v-if="tow['nbGearWithCatch']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.nbGearWithCatch')"
            :value="tow['nbGearWithCatch']"
            type="text"
          />
        </b-col>
        <b-col v-if="tow['towCount']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.towCount')"
            :value="tow['towCount']"
            type="text"
          />
        </b-col>
        <b-col v-if="tow['setNumber']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.setNumber')"
            :value="tow['setNumber']"
            type="text"
          />
        </b-col>
        <b-col v-if="tow['setsCount']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.setsCount')"
            :value="tow['setsCount']"
            type="text"
          />
        </b-col>
        <b-col v-if="tow['sonarStart']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.sonarStart')"
            :value="$showDate(tow['sonarStart'])"
            type="text"
          />
        </b-col>
        <b-col v-if="tow['sonarEnd']" lg="4" sm="6" cols="12">
          <LabelView
            :label="$t('editTrip.effortStep.sonarEnd')"
            :value="$showDate(tow['sonarEnd'])"
            type="text"
          />
        </b-col>
      </b-row>
      <div v-if="tow['midPosition']">
        <b-row>
          <b-col lg="4" sm="6" cols="12">
            <label :id="'midPositionLabel'">
              {{ $t("editTrip.effortStep.midPosition") }}
            </label>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="tow['midPosition']['latitude']" lg="4" sm="6" cols="12">
            <LabelView
              :label="$t('latitude')"
              :value="
                convertDD2toDMS(tow['midPosition']['latitude'], 'lat') +
                  ' = ' +
                  tow['midPosition']['latitude'] +
                  '°'
              "
              type="text"
            />
          </b-col>
          <b-col v-if="tow['midPosition']['longitude']" lg="4" sm="6" cols="12">
            <LabelView
              :label="$t('longitude')"
              :value="
                convertDD2toDMS(tow['midPosition']['longitude'], 'lon') +
                  ' = ' +
                  tow['midPosition']['longitude'] +
                  '°'
              "
              type="text"
            />
          </b-col>
        </b-row>
      </div>
    </fieldset>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { formatTimeIntervalInMinutes } from "@/utils/utils";
import LabelView from "@/components/LabelView.vue";
import Geolocation from "@/utils/geolocation.js";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  name: "InfoViewer",
  components: {
    LabelView,
    UnitConverter
  },

  computed: {
    ...mapState({
      systemsLists: state => state.systemsLists
    })
  },
  props: {
    tow: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatDuration(duration) {
      return formatTimeIntervalInMinutes(duration);
    },
    convertDD2toDMS(position, type) {
      return Geolocation.convertDD2toDMS(position, type).toString;
    }
  }
};
</script>
<style lang="scss" scoped>
#midPositionLabel {
  padding: 0.25rem 0;
  margin-bottom: 0;
  color: $lightColor;
}
</style>
