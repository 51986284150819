<template>
  <div
    id="pageTripList_modules"
    class="page modulesPage triplist insideApp"
    :class="{ expired: subscriptionIsExpired }"
  >
    <h1>
      {{ $t("triplist.title", { name: subscription.module.label }) }}
    </h1>
    <h2 v-if="subscription.module.isDemo" class="redText">
      {{ $t("triplist.demoSubtitle") }}
    </h2>
    <h2>{{ $t("triplist.subtitle") }}</h2>

    <div class="content">
      <b-row class="topPageMenu">
        <b-col cols="12" sm="8">
          <a href="#dfo-instruction">
            {{ $t("triplist.dfoInstructions.button") }}
          </a>
        </b-col>
        <b-col cols="12" sm="4" class="settingsLink">
          <br />
          <a
            v-if="subscription"
            @click="openSettings"
            :class="settingsClass"
            v-html="$t('triplist.settings')"
          />
        </b-col>
      </b-row>
      <QuotaBar />
      <div class="topContentMessage">
        <p class="topContentError" v-if="subscriptionNotReady">
          {{ $t("triplist.no_settings") }}
        </p>
        <p class="topContentError" v-if="subscriptionIsExpired">
          {{ $t("triplist.subscription.expired") }}
        </p>
        <router-link
          v-if="subscriptionIsExpired"
          class="btnrenew"
          :to="{
            name: 'modulesorder',
            params: { id: subscription.module.id }
          }"
        >
          <span>
            <span>{{ $t("lobby.renew") }}</span>
          </span>
        </router-link>
        <p class="topContentError" v-if="elogKeyNotReady">
          {{ $t("triplist.no_elogkey") }}
        </p>
        <p class="topContentWarning" v-if="reviewSubscription">
          {{ $t("triplist.review_settings") }}
        </p>
        <p class="topContentWarning" v-if="subscription.module.isDemo">
          {{ $t("triplist.demoWarning") }}
        </p>
        <p class="topContentWarning" v-if="elogKeyExpired">
          {{ $t("message.expired_elogkey") }}
        </p>
        <p class="topContentWarning" v-if="subscriptionExpiresSoon">
          {{ $t("triplist.subscription.almost") }}
        </p>
        <router-link
          class="btnrenew"
          :to="{
            name: 'modulesorder',
            params: { id: subscription.module.id }
          }"
          v-if="subscriptionExpiresSoon"
        >
          <span>
            <span>{{ $t("lobby.renew") }}</span>
          </span>
        </router-link>
        <p class="alreadyHaveAnOpenTrip topContentError hide">
          {{ $t("triplist.already_open") }}
        </p>
        <p class="demoWarningMessage topContentError hide">
          {{ $t("triplist.is_demo") }}
          <br />{{ $t("triplist.full_message") }},
          <a class="buyLobsterModuleDirectLink" data-uuid="">
            {{ $t("click_here") }}
          </a>
          .
        </p>
      </div>
      <!-- for mobile -->
      <a
        class="btn-add"
        @click="clickAddTrip"
        v-if="subscription"
        :class="cannotAddTrip && 'deactivated'"
      >
        <span>
          <span>{{ $t("triplist.add") }}</span>
        </span>
      </a>
      <div class="trip-list-wrapper">
        <a
          class="btn-add"
          @click="clickAddTrip"
          v-if="subscription"
          :class="cannotAddTrip && 'deactivated'"
        >
          <span>
            <span>{{ $t("triplist.add") }}</span>
          </span>
        </a>
        <h2 class="tableTitle">
          <span>
            <span>
              <span>
                {{ $t("triplist.open") }}
                ({{ numOpenedTrips | formatNumber(this) }})
              </span>
            </span>
          </span>
        </h2>
        <p v-if="hasOpenTrip" class="openTripWarning">
          {{ $t("triplist.message.opentrip") }}
        </p>
        <table class="tableList open">
          <tbody>
            <tr class="tableHead">
              <th class="sortingDatetime-column column-title">
                {{ $t("date") }}
              </th>
              <th class="status-column column-title">{{ $t("status") }}</th>
              <th class="column-title">
                {{ $t("actions") }}
              </th>
            </tr>
            <tr v-if="!hasOpenTrip" class="empty-list">
              <td
                class="empty-list sortingDatetime-column sortingDatetime tripList"
              >
                ...
              </td>
              <td class="empty-list status-column status tripList center">
                ...
              </td>
              <td class="empty-list actions-btn tripList right">
                ...
              </td>
            </tr>
            <tr v-else class="open">
              <td class="sortingDatetime-column sortingDatetime tripList">
                {{ $showDateOnly(openTripSortingDatetime) }}
              </td>
              <td class="status-column status tripList center">
                <span class="open">
                  !
                  <ul class="tooltiptext">
                    <li class="open">{{ $t("triplist.status.open") }}</li>
                  </ul>
                </span>
              </td>
              <td class="actions-btn-column actions-btn tripList right">
                <router-link
                  class="btn-edit"
                  :to="{
                    name: 'subformedittrip',
                    params: { uuid: subscription.uuid, subform: subformId }
                  }"
                  v-if="subscription"
                >
                  <span>
                    <span>{{ $t("triplist.action.edit") }}</span>
                  </span>
                </router-link>
                <router-link
                  class="btn-view"
                  :to="{
                    name: 'closetrip',
                    params: {
                      uuid: subscription.uuid,
                      luid: openTrip['logbookUID']
                    }
                  }"
                  v-if="subscription && openTrip.status === 'review'"
                >
                  <span>
                    <span>{{ $t("triplist.action.close") }}</span>
                  </span>
                </router-link>
                <a class="btn-delete" @click="clickDeleteTrip">
                  <span>{{ $t("triplist.action.delete") }}</span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h2 class="tableTitle">
        <span>
          <span>
            <span>
              {{ $t("triplist.closed") }}
              ({{ closedTrips.length | formatNumber(this) }})
            </span>
          </span>
        </span>
      </h2>
      <table class="tableList open">
        <tbody>
          <tr class="tableHead">
            <th class="sortingDatetime-column column-title">
              {{ $t("date") }}
            </th>
            <th class="status-column column-title">{{ $t("status") }}</th>
            <th class="column-title">
              {{ $t("actions") }}
            </th>
          </tr>
          <tr v-if="closedTrips.length === 0" class="empty-list">
            <td
              class="empty-list sortingDatetime-column sortingDatetime tripList"
            >
              ...
            </td>
            <td class="empty-list status-column status tripList center">...</td>
            <td class="empty-list actions-btn tripList right">
              ...
            </td>
          </tr>
          <tr
            v-for="trip in closedTrips"
            :key="trip.logbookUID"
            class="close2"
            :class="closedTripClass(trip)"
          >
            <td class="sortingDatetime-column sortingDatetime tripList">
              {{ $showDateOnly(trip["creation"]) }}
            </td>
            <td class="status-column status tripList center">
              <span class="close2 ready" v-if="trip['status'] === 'closed'">
                !
                <ul class="tooltiptext">
                  <li class="closeStatus">{{ $t("triplist.status.close") }}</li>
                  <li class="ready">{{ $t("triplist.status.ready") }}</li>
                </ul>
              </span>
              <span class="close2 notReady" v-if="trip['status'] === 'landing'">
                !
                <ul class="tooltiptext">
                  <li class="closeStatus">{{ $t("triplist.status.close") }}</li>
                  <li class="notReady">
                    {{ $t("triplist.status.notReady") }}
                  </li>
                </ul>
              </span>
            </td>
            <td class="actions-btn-column actions-btn tripList right">
              <a class="btn-view" @click="clickSendTrip(trip['logbookUID'])">
                <span v-if="trip['status'] === 'closed'">
                  <span>{{ $t("triplist.action.send") }}</span>
                </span>
                <span v-else-if="trip['status'] === 'landing'">
                  <span>{{ $t("triplist.action.landing") }}</span>
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <h2 class="tableTitle">
        <span>
          <span>
            <span>
              {{ $t("triplist.sent") }}
              ({{ syncCount | formatNumber(this) }})
              <span class="titleNote">
                {{ $t("triplist.message.uneditable") }}
              </span>
            </span>
          </span>
        </span>
      </h2>
      <!--
      <p
        v-if="tripNotSentToDfo && !subscription.module.isDemo"
        class="failTripWarning"
      >
        {{ $t("triplist.message.failure") }}
      </p>
      -->
      <table class="tableList synched">
        <tbody>
          <tr class="tableHead">
            <th class="sortingDatetime-column column-title">
              {{ $t("date") }}
            </th>
            <th class="status-column column-title">{{ $t("status") }}</th>
            <th class="column-title">
              {{ $t("actions") }}
            </th>
          </tr>
          <tr v-if="!syncCount" class="empty-list">
            <td
              class="empty-list sortingDatetime-column sortingDatetime tripList"
            >
              ...
            </td>
            <td class="empty-list status-column status tripList center">...</td>
            <td class="empty-list actions-btn tripList right">
              ...
            </td>
          </tr>
          <tr v-for="trip in tripList" :key="trip.logbookUID">
            <td class="sortingDatetime-column sortingDatetime tripList">
              {{ $showDateOnly(trip.sortingDatetime) }}
            </td>
            <td class="status-column status tripList center">
              <span v-if="trip.sentToDFO === true" class="synched sent">
                ✓
                <ul class="tooltiptext">
                  <li>{{ $t("triplist.status.synched") }}</li>
                  <li>{{ $t("triplist.status.sent") }}</li>
                </ul>
              </span>
              <span
                v-else-if="trip.sentToDFO === false"
                class="synched notSent"
              >
                ✓
                <ul class="tooltiptext">
                  <li>{{ $t("triplist.status.synched") }}</li>
                  <li>{{ $t("triplist.status.processing") }}</li>
                </ul>
              </span>
              <span
                v-else-if="subscription.module.isDemo"
                class="synched notSentDemo"
              >
                ✓
                <ul class="tooltiptext">
                  <li>{{ $t("triplist.status.synched") }}</li>
                  <li>{{ $t("triplist.status.notSent") }}</li>
                </ul>
              </span>
              <span v-else>
                <img class="wheel" :src="logoWheel" width="25" height="25" />
                <ul class="tooltiptext">
                  <li>{{ $t("triplist.status.synched") }}</li>
                  <li>{{ $t("triplist.status.inProgress") }}</li>
                </ul>
              </span>
            </td>
            <td class="actions-btn-column actions-btn tripList right">
              <a class="btn-view" @click="clickViewTrip(trip['logbookUID'])">
                {{ $t("triplist.action.view") + " " + trip["logbookUID"] }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div id="dfo-instruction">
        <b-button
          block
          variant="outline-dark"
          @click="$showDfoInstructions(subscription.module.form)"
        >
          {{ $t("triplist.dfoInstructions.button") }}
        </b-button>
        <b-button
          block
          variant="outline-dark"
          @click="$showDfoInstructions('f223')"
        >
          {{ $t("triplist.dfoInstructions.lostgear") }}
        </b-button>
        <b-button
          block
          variant="outline-dark"
          @click="$showDfoInstructions('f222')"
        >
          {{ $t("triplist.dfoInstructions.interaction") }}
        </b-button>
        <b-button
          block
          variant="outline-dark"
          @click="$showDfoInstructions('f233')"
        >
          {{ $t("triplist.dfoInstructions.inactivity") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import QuotaBar from "@/components/QuotaBar.vue";
import logoWheel from "@/assets/loader.gif";

export default {
  name: "Triplist",
  data() {
    return {
      subform: "",
      isSubscriptionReady: false,
      logoWheel
    };
  },
  components: {
    QuotaBar
  },
  computed: {
    ...mapState({
      openTrip: state => state.currentOpenTrip,
      tripList: state => state.currentTripList,
      closedTrips: state => state.closedTrips,
      subformOptions: state => state.editTripSubform.subformOptions,
      subformId: state => state.editTripSubform.subform,
      userProfile: state => state.userProfile,
      subscriptions: state => state.currentSubscription,
      systemsLists: state => state.systemsLists
    }),
    ...mapGetters(["getPropertyValue"]),
    tripNotSentToDfo() {
      for (let trip of this.tripList) {
        if (trip.sentToDFO === false) {
          return true;
        }
      }
      return false;
    },
    settingsClass() {
      if (this.hasOpenTrip) {
        return "deactivatedSettings";
      }
      if (this.showAlert) {
        return "alertClickHere";
      }
      return "";
    },
    selectedOptionWithDefault: {
      get() {
        return this.subform || this.subformId;
      },
      set(newValue) {
        this.subform = newValue;
      }
    },
    openTripSortingDatetime() {
      var sortingDatetime = this.openTrip["creation"];
      if (
        "datetime" in this.openTrip.departure &&
        this.openTrip.departure["datetime"] &&
        this.openTrip.departure["datetime"].length > 0
      ) {
        sortingDatetime = this.openTrip.departure["datetime"];
      }
      return sortingDatetime;
    },
    subscription() {
      return this.$store.getters.SUBSCRIPTION;
    },
    tripList() {
      return this.$store.getters.TRIP_LIST;
    },
    numOpenedTrips() {
      return this.hasOpenTrip ? 1 : 0;
    },
    syncCount() {
      return this.tripList.length;
    },
    subscriptionNotReady() {
      return !this.isSubscriptionReady;
    },
    subscriptionIsExpired() {
      return this.subscription.isExpired;
    },
    subscriptionExpiresSoon() {
      return this.subscription.willExpire;
    },
    reviewSubscription() {
      return this.subscription.needReview;
    },
    elogKeyNotReady() {
      const dfoActive = this.subscription.module.isSendToDFO;
      const hasElogKey = this.userProfile.hasElogKey;
      return dfoActive && !hasElogKey;
    },
    elogKeyExpired() {
      const dfoActive = this.subscription.module.isSendToDFO;
      const elogKeyExpired = this.userProfile.isElogKeyExpired;
      return dfoActive && elogKeyExpired;
    },
    hasOpenTrip() {
      return ["open", "review"].includes(this.openTrip.status);
    },
    cannotAddTrip() {
      return (
        this.subscriptionNotReady ||
        this.subscriptionIsExpired ||
        this.elogKeyNotReady ||
        this.elogKeyExpired ||
        this.hasOpenTrip
      );
    },
    showAlert() {
      return this.subscriptionNotReady || this.reviewSubscription;
    }
  },
  methods: {
    ...mapActions([
      "updateIsFinalReview",
      "setCurrentOpenTrip",
      "generateSubformOptions",
      "getSubform",
      "generateTrip"
    ]),
    openSettings() {
      if (this.hasOpenTrip) {
        this.$store.dispatch(
          "alert/error",
          this.$t("triplist.setting_tripOpen")
        );
        return;
      }
      this.$router.push({
        name: "modulesettings",
        params: { uuid: this.subscription.uuid }
      });
    },
    clickDeleteTrip() {
      this.$swal({
        title: this.$t("areYouSure"),
        text: this.$t("closeDeleteFormTrip"),
        icon: "warning",
        buttons: [this.$t("no"), this.$t("yes")],
        dangerMode: true
      }).then(willDelete => {
        if (willDelete) {
          this.setCurrentOpenTrip({
            uuid: this.subscription.uuid,
            currentOpenTrip: null
          });
        }
      });
    },
    closedTripClass(trip) {
      if (trip["status"] === "closed") {
        if (this.checkDateOver72Hours(trip["arrival"]["datetime"])) {
          return "ready & redAlert";
        }
        return "ready";
      }
      return "notReady";
    },
    checkDateOver72Hours(stringDate) {
      var past72Hours = 60 * 60 * 72; //seconds * mins * hours
      var timestampNow = Date.now() / 1000;
      var timestampOriginalDate = Date.parse(stringDate) / 1000;
      var diffTime = timestampNow - timestampOriginalDate;
      return past72Hours < diffTime;
    },
    clickSendTrip(id) {
      this.$router.push({
        name: "sendtrip",
        params: {
          uuid: this.subscription.uuid,
          luid: id
        }
      });
    },
    clickViewTrip(id) {
      this.$router.push({
        name: "viewtrip",
        params: {
          uuid: this.subscription.uuid,
          luid: id
        }
      });
    },
    clickAddTrip() {
      if (this.subscriptionNotReady) {
        this.$store.dispatch("alert/error", this.$t("triplist.no_settings"));
      } else if (this.subscriptionIsExpired) {
        this.$store.dispatch(
          "alert/error",
          this.$t("triplist.subscription.expired")
        );
      } else if (this.elogKeyNotReady) {
        this.$store.dispatch("alert/error", this.$t("triplist.no_elogkey"));
      } else if (this.elogKeyExpired) {
        this.$store.dispatch("alert/error", this.$t("message.expired_elogkey"));
      } else if (this.hasOpenTrip) {
        this.$store.dispatch("alert/error", this.$t("triplist.already_open"));
      } else {
        this.updateIsFinalReview(false);
        this.generateTrip(this);
      }
    },
    isLicenceValid(licence) {
      if (licence && licence.fishingLicenceNumber && licence.target) {
        return true;
      }
      return false;
    },
    getPropertyContext() {
      const context = {};
      const prefs = this.subscription.preferences;
      if (prefs.subform) context.subforms = prefs.subform;
      if (prefs.dfoRegion) context.regions = prefs.dfoRegion;
      if (prefs.gears) context.gears = prefs.gears;

      if (prefs.licences && prefs.licences.length > 0) {
        const licence = prefs.licences[0];
        if (licence.target) context.targets = licence.target;
        if (licence.area) context.areas = licence.area;
      }

      return context;
    },
    subscriptionIsReady() {
      let isReady = false;
      const prefs = this.subscription.preferences;
      if (prefs) {
        const vessel = prefs.vessel;
        const operator = prefs.operator;
        const licences = prefs.licences;
        if (
          prefs.subform &&
          prefs.dfoRegion &&
          vessel &&
          (vessel.name || vessel.registeredNumber) &&
          operator &&
          (operator.name || operator.fisherIdentificationNumber) &&
          licences &&
          licences.length > 0
        ) {
          isReady = true;
          for (const licence of licences) {
            isReady = isReady && this.isLicenceValid(licence);
          }
          // TODO: Inutile - tous les tests qui suivent n'ont aucun effet sur la valeur de isReady :-)
          const context = this.getPropertyContext();
          if (
            isReady &&
            this.getPropertyValue("hasDeparturePort", context) &&
            prefs.departurePort
          ) {
            isReady = isReady && true;
          }
          if (
            isReady &&
            this.getPropertyValue("hasLandingPort", context) &&
            prefs.arrivalPort
          ) {
            isReady = isReady && true;
          }
          if (
            isReady &&
            this.getPropertyValue("hasCrewNb", context) &&
            prefs.crewNb
          ) {
            isReady = isReady && true;
          }
          if (
            isReady &&
            this.getPropertyValue("hasPartnership", context) &&
            prefs.partnership
          ) {
            isReady = isReady && true;
          }
          if (
            isReady &&
            this.getPropertyValue("hailOutCallOption", context) == 2 &&
            prefs.hailOutCallCompany
          ) {
            isReady = isReady && true;
          }
          if (
            isReady &&
            this.getPropertyValue("hasHailIn", context) == 2 &&
            prefs.hailCallCompany
          ) {
            isReady = isReady && true;
          }
          if (
            isReady &&
            this.getPropertyValue("hasMeshType", context) &&
            prefs.meshType
          ) {
            isReady = isReady && true;
          }
          if (
            isReady &&
            this.getPropertyValue("trapSizes", context) &&
            this.getPropertyValue("trapSizes", context).length &&
            prefs.trapSize
          ) {
            isReady = isReady && true;
          }
          if (
            isReady &&
            this.getPropertyValue("maxMeshSize", context) &&
            prefs.meshSize
          ) {
            isReady = isReady && true;
          }
          if (
            isReady &&
            this.getPropertyValue("hasBaitUsed", context) &&
            prefs.firstBaitType
          ) {
            isReady = isReady && true;
          }
        }
      }
      return isReady;
    }
  },
  mounted() {
    this.generateSubformOptions();
    this.getSubform();
    this.isSubscriptionReady = this.subscriptionIsReady();

    this.$store.dispatch("updateTripList");
  },
  beforeRouteLeave(to, from, next) {
    if (["closetrip", "reviewtrip", "sendtrip", "viewtrip"].includes(to.name)) {
      const luid = to.params.luid;
      this.$store.dispatch("selectTrip", luid).then(next());
    } else {
      next();
    }
  },
  filters: {
    formatNumber(value, context) {
      return value ? value : context.$t("triplist.none");
    }
  }
};
</script>

<style lang="scss" scoped>
.redText {
  color: red;
}

.topPageMenu > div:first-child {
  text-align: left;
}

.btnrenew {
  display: block;
  height: 55px;
  background-color: wheat;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 11px;

  &:hover {
    background-color: bisque;
  }

  > span {
    display: table;
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;

    > span {
      display: table-cell;
      vertical-align: middle;
      position: relative;
      text-align: center;
    }
  }
}
.settingsLink {
  text-align: right;
}
</style>
