<template>
  <div
    v-if="currentTrip.speciesAtRisk && currentTrip.speciesAtRisk.length > 0"
    name="speciesAtRiskViewer"
  >
    <h4
      v-if="currentTrip.speciesAtRisk && currentTrip.speciesAtRisk.length > 0"
      class="level1 viewStep nextIsTitle"
    >
      {{ $t("editTrip.speciesAtRiskStep.title1") }}
    </h4>
    <div
      v-for="(sar, index) in currentTrip.speciesAtRisk"
      :key="'sar-' + index"
      class="towEntriesContainer"
    >
      <div>
        <fieldset class="fieldsetViewTrip">
          <h4 class="level2 sticky viewStep">
            Interaction&nbsp;{{ index + 1 }}
          </h4>
          <b-row>
            <b-col lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.species')"
                :value="sar['species']"
                :options="speciesOptions"
                type="select"
              />
            </b-col>
            <b-col v-if="sar['occursAt']['datetime']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.datetime')"
                :value="$showDate(sar['occursAt']['datetime'])"
                type="text"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="sar['occursAt']['latitude']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('latitude')"
                :value="
                  convertDD2toDMS(sar['occursAt']['latitude'], 'lat') +
                    ' = ' +
                    sar['occursAt']['latitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
            <b-col v-if="sar['occursAt']['longitude']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('longitude')"
                :value="
                  convertDD2toDMS(sar['occursAt']['longitude'], 'lon') +
                    ' = ' +
                    sar['occursAt']['longitude'] +
                    '°'
                "
                type="text"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col v-if="sar['specimenCondition']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.specimenCondition')"
                :value="sar['specimenCondition']"
                :options="systemsLists.sarCondition"
                type="select"
              />
            </b-col>
            <b-col v-if="sar['nbSpecimen']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.speciesAtRiskStep.nbSpecimen')"
                :value="sar['nbSpecimen']"
                type="text"
              />
            </b-col>
            <b-col v-if="sar['weight']" lg="4" sm="6" cols="12">
              <UnitConverter
                :label="$t('editTrip.speciesAtRiskStep.weight')"
                :value="sar['weight']"
                unit="weight"
                viewOnly
              />
            </b-col>
            <b-col v-if="sar['remark']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('editTrip.remarkTitle')"
                :value="sar['remark']"
                type="text"
              />
            </b-col>
            <b-col v-if="sar['closeDatetime']" lg="4" sm="6" cols="12">
              <LabelView
                :label="$t('dataGroupClosureDate')"
                :value="$showDate(sar['closeDatetime'])"
                type="text"
              />
            </b-col>
          </b-row>
        </fieldset>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Geolocation from "@/utils/geolocation.js";
import LabelView from "@/components/LabelView.vue";
import UnitConverter from "@/components/widgets/UnitConverter.vue";

export default {
  name: "SpeciesAtRiskViewer",
  components: {
    LabelView,
    UnitConverter
  },
  data: function() {
    return {
      speciesOptions: []
    };
  },
  computed: {
    ...mapGetters(["getPropertyValue"]),
    ...mapState({
      subscription: state => state.currentSubscription,
      systemsLists: state => state.systemsLists,
      currentTrip: state => state.selectedTrip
    }),
    sarSpecies() {
      return this.getPropertyValue(
        "sarSpecies",
        { forms: this.currentTrip.form },
        []
      );
    }
  },
  watch: {
    systemsLists() {
      // @FIXME: Il faudra le changé lorsqu'il n'y aura plus d'option dans data
      this.$router.go();
    }
  },
  methods: {
    convertDD2toDMS(position, type) {
      return Geolocation.convertDD2toDMS(position, type).toString;
    }
  },
  beforeMount() {
    this.speciesOptions = [{ value: null, text: "---------" }];

    const moduleSarSpecies = this.sarSpecies;
    if (moduleSarSpecies && moduleSarSpecies.length > 0) {
      const values = Object.values(moduleSarSpecies);
      for (let index = 0; index < values.length; index++) {
        const element = this.systemsLists.sarSpecies.find(
          x => x.value === values[index]
        );
        this.speciesOptions.push(element);
      }
    } else {
      const sarSpecies = this.systemsLists.sarSpecies;
      for (let index = 0; index < sarSpecies.length; index++) {
        const element = sarSpecies[index];
        if (element.text.indexOf("------") < 0) {
          this.speciesOptions.push(element);
        }
      }
    }

    const mmiSpecies = this.systemsLists.mmiSpecies;
    for (let index = 0; index < mmiSpecies.length; index++) {
      const element = mmiSpecies[index];
      if (element.text != "------") {
        this.speciesOptions.push(element);
      }
    }
  }
};
</script>
