<template>
  <div>
    <!-- Button shown to users -->
    <a class="action-btn secondLine" @click="print">
      <span>
        <span>{{ $t("crew.register.print") }}</span>
      </span>
    </a>
    <!-- Content that will be printed, better to handle in template than string manipulation -->
    <div id="crewcontent" ref="crewcontent" style="display: none;">
      <div
        style="width: 100%; background-color: white; font-size: 16px; font-family: sans-serif; print-color-adjust: exact"
      >
        <div style="font-size: 0.88em; text-align: center; padding: 16px;">
          <img
            src="/img/icons/jobel-logo-transparent-96.png"
            style="vertical-align: middle;"
          />
          {{ version }}
        </div>
        <div
          style="border-top-width: 1px; border-bottom-width: 1px; border-style: solid; border-color: black; background-color: darkgrey; padding: 16px;"
        >
          <p
            style="text-align: center; font-size: 2em; font-weight: bold; margin: 0; padding: 16px;"
          >
            {{ $t("crew.register.title") }}
          </p>
        </div>
        <div>
          <p style="text-align: center; font-size: 1.5em; font-weight: bold;">
            {{ $t("trip") }} {{ tripNumber }} - {{ logbookId }}
          </p>
        </div>
        <table
          style="font-size: 16px; border: 1px solid black; margin-left: auto; margin-right: auto; border-collapse: collapse;"
        >
          <tr>
            <td
              style="font-weight: bold; background-color: lightgrey; border: 1px solid black; padding: 4px 15px;"
            >
              {{ $t("vessel") }}
            </td>
            <td style="border: 1px solid black; padding: 4px 15px;">
              {{ vessel.name }} #{{ vessel.registeredNumber }}
            </td>
          </tr>
          <tr>
            <td
              style="font-weight: bold; background-color: lightgrey; border: 1px solid black; padding: 4px 15px;"
            >
              {{ $t("date") }}
            </td>
            <td style="border: 1px solid black; padding: 4px 15px;">
              {{ $showDateOnly(departure) }}
            </td>
          </tr>
          <tr v-if="targetSpecies.length > 0">
            <td
              style="font-weight: bold; background-color: lightgrey; border: 1px solid black; padding: 4px 15px;"
            >
              {{ $t("fields.targetSpecies") }}
            </td>
            <td style="border: 1px solid black; padding: 4px 15px;">
              {{ targetSpecies.join(", ") }}
            </td>
          </tr>
        </table>

        <div>
          <p style="text-align: center; font-size: 1.5em; font-weight: bold;">
            {{ $t("section.operator") }}
          </p>
        </div>
        <table
          style="font-size: 16px; margin-left: auto; margin-right: auto; border-collapse: collapse;"
        >
          <tr>
            <th style="padding: 4px 15px;">{{ $t("crew.name.label") }}</th>
            <th style="padding: 4px 15px;">{{ $t("fin.short") }}</th>
            <th style="padding: 4px 15px;">{{ $t("crew.address.label") }}</th>
          </tr>
          <tr style="background-color: silver;">
            <td style="border: 1px solid black; padding: 4px 15px;">
              {{ operator.name }}
            </td>
            <td style="border: 1px solid black; padding: 4px 15px;">
              {{ operator.fisherIdentificationNumber }}
            </td>
            <td style="border: 1px solid black; padding: 4px 15px;"></td>
          </tr>
        </table>

        <div v-if="crew?.length">
          <div>
            <p style="text-align: center; font-size: 1.5em; font-weight: bold;">
              {{ $t("crew.register.members") }}
            </p>
          </div>
          <table
            style="font-size: 16px; margin-left: auto; margin-right: auto; border-collapse: collapse;"
          >
            <tr
              v-for="(crewman, index) in crew"
              :key="index"
              :style="oddOrEvenRow(index)"
            >
              <td style="border: 1px solid black; padding: 4px 15px;">
                {{ crewman.name }}
              </td>
              <td style="border: 1px solid black; padding: 4px 15px;">
                {{ crewman.fisherIdentificationNumber || "N/A" }}
              </td>
              <td style="border: 1px solid black; padding: 4px 15px;">
                {{ crewman.address }}
              </td>
            </tr>
          </table>
        </div>
        <div>
          <p style="text-align: center; font-size: 1.5em; font-weight: bold;">
            {{ $t("fields.crewNb") }}: {{ crewNb }}
          </p>
        </div>
      </div>
    </div>
    <!-- Required for printing without having to create a new window/tab -->
    <iframe
      :srcdoc="content"
      title="Joy to the world!"
      id="crewprint"
      ref="crewprint"
      style="display: none; position: absolute; top:0; width:100%"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "CrewPrinter",
  components: {},
  props: {
    showErrorData: Number,
    addErrorToParent: Function
  },
  data: function() {
    return {
      targetSpecies: [],
      content: ""
    };
  },
  computed: {
    ...mapState({
      logbookId: state => state.selectedTrip.logbookUID,
      tripNumber: state => state.selectedTrip.tripNumber,
      crew: state => state.selectedTrip.crew,
      crewNb: state => state.selectedTrip.crewNb,
      operator: state => state.selectedTrip.operator,
      vessel: state => state.selectedTrip.vessel,
      efforts: state => state.selectedTrip.efforts,
      departure: state => state.selectedTrip.departure.datetime,
      version: state => state.selectedTrip.software.version,
      species: state => state.systemsLists.species
    })
  },
  methods: {
    updateSpecies() {
      let result = [];
      let targets = new Set();
      for (const effort of this.efforts) {
        targets.add(effort.target);
      }
      let ids = Array.from(targets);

      result = ids.map(id => {
        return this.species.find(s => s.value === id).text;
      });

      this.targetSpecies = result.sort();
    },
    oddOrEvenRow(index) {
      return index % 2 === 1
        ? "background-color: silver;"
        : "background-color: rgb(226, 226, 226);";
    },
    print() {
      this.$refs.crewprint.focus();
      this.$refs.crewprint.contentWindow.print();
    }
  },
  beforeMount() {
    this.updateSpecies();
  },
  mounted() {
    this.content = this.$refs.crewcontent.innerHTML;
  }
};
</script>
