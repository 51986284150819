import BaseModel from "./BaseModel.js";

export default class TowModel extends BaseModel {
  agglomerationNumber = null;
  airTemp = null;
  averageBottomDepth = null;
  averageGearDepth = null;
  averageGearDistance = null;
  averageTowSpeed = null;
  baitUsages = [];
  baitCategory = null;
  baitType = null;
  buoysCount = null;
  bycatches = [];
  catches = [];
  distance = null;
  doorSpread = null;
  endArea = null;
  endDate = null;
  endGearDepth = null;
  endPosition = null;
  farGearPosition = null;
  firstCount = null;
  fishingMethod = null;
  fishSite = null;
  gearDepthCategory = null;
  gearLossEvents = [];
  hookLostCount = null;
  hooksCount = null;
  hookSize = null;
  hookType = null;
  linesCount = null;
  lobsterPopulationSamples = [];
  maximumGearDepth = null;
  meshSize = null;
  middleGearDepth = null;
  midPosition = null;
  minimumGearDepth = null;
  nbBigLobster = null;
  nbGearHauled = null;
  nbGearLost = null;
  nbGearWithCatch = null;
  nbSelfVnotched = null;
  nbVnotchObserved = null;
  optimalDepth = null;
  populationDensity = null;
  potentialSiteWeight = null;
  protectedBait = null;
  releaseCause = null;
  remark = null;
  riggedPartLength = null;
  secteur = null;
  section = null;
  setNumber = null;
  setsCount = null;
  skatesCount = null;
  soakDuration = null;
  sonarEnd = null;
  sonarStart = null;
  spawningActivity = null;
  speciesSize = null;
  spotterPlane = null;
  ssSection = null;
  startArea = null;
  startDate = null;
  startGearDepth = null;
  startPosition = null;
  strikesCount = null;
  tags = [];
  targetDepth = null;
  totalGearLength = null;
  towCount = null;
  towNumIdx = null;
  trapSize = null;
  verticalSpread = null;
  warpLength = null;
  waterSurfaceTemp = null;
  weatherConditions = null;
  wingSpread = null;

  // TODO: a static var is sufficient to obtain a unique number but it's not
  // exactly what we need for the tow number. The requirement defined by rule
  // 797, the tow number is reset to 1 for each ELOG.

  // I don't believe that the solution comes from this class. Or at least, it
  // does not reside in changing the idx variable. I foresee the need for a
  // post-processing of the whole trip before sending it to the server. The
  // operation would resequence all tows and update all references.
  static idx = 1;
  constructor() {
    super();
    this.towNumIdx = TowModel.idx++;
    // Object.seal(this);
  }
}
