<template>
  <IntegerInput
    v-if="useDays"
    v-model="durationInDays"
    :label="$t('editTrip.effortStep.soakDurationInDays')"
    :required="required"
    :min="1"
    :max="maxNumberOfDays"
    :forceErrorMsg="forceErrorMsg"
    :refeshError="refeshError"
    :placeholder="placeholder"
    @error="focusChanged"
  />
  <p
    v-else
    :name="id"
    :class="{ uneditable: uneditable }"
    class="field-wrapper"
  >
    <span class="label">
      <span>
        <label>
          {{ $t("editTrip.effortStep.soakDuration") }}
        </label>
      </span>
    </span>
    <b-container class="soakDurationWidget">
      <b-row>
        <b-col cols="4">
          <b-form-select
            :options="dayOption"
            v-model="selectedDay"
            :disabled="disabled"
            @change="setDuration()"
            class="selector days"
            :class="errorClass"
          />
        </b-col>
        <b-col cols="4">
          <b-form-select
            :options="hourOption"
            v-model="selectedHour"
            :disabled="disabled"
            @change="setDuration()"
            class="selector days"
            :class="errorClass"
          />
        </b-col>
        <b-col cols="4">
          <b-form-select
            :options="minuteOption"
            v-model="selectedMinute"
            :disabled="disabled"
            @change="setDuration()"
            class="selector days"
            :class="errorClass"
          />
        </b-col>
      </b-row>
    </b-container>
    <label v-if="isError || forceErrorMsg" class="error">
      {{ errorMessage }}
    </label>
  </p>
</template>

<script>
import IntegerInput from "@/components/subformEditTrip/widgets/IntegerInput.vue";
import Validator from "@/utils/validator.js";

export default {
  name: "SoakDuration",
  components: {
    IntegerInput
  },
  props: {
    value: {
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    refeshError: {
      default: 0
    },
    errorMsg: {
      type: String,
      default: ""
    },
    forceErrorMsg: {
      default: undefined
    },
    uneditable: {
      type: Boolean,
      default: false
    },
    soakDurationMax: {
      type: Number,
      default: 99999
    },
    useDays: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: this.$options.name + "-" + (Math.random() * 100000).toFixed(0),
      durationInDays: null,
      defaultErrorMsg: "",
      errorClass: { error: false },
      selectClass: [],
      dayOption: [{ value: 0, text: "0 Day" }],
      hourOption: [{ value: 0, text: "0 Hour" }],
      minuteOption: [{ value: 0, text: "0 Minute" }],
      selectedDay: 0,
      selectedHour: 0,
      selectedMinute: 0,
      isError: false
    };
  },
  computed: {
    valueLength() {
      return this.value ? this.value.length : null;
    },
    errorMessage() {
      let msg = this.defaultErrorMsg;
      if (this.forceErrorMsg) {
        msg = this.forceErrorMsg;
      } else if (this.errorMsg) {
        msg = this.errorMsg;
      }

      return msg;
    },
    filter() {
      return {
        type: "duration",
        required: this.required,
        min: 1,
        max: this.soakDurationMax
      };
    },
    maxNumberOfDays() {
      return Math.trunc(this.soakDurationMax / (24 * 60));
    }
  },
  watch: {
    refeshError() {
      this.setError(this.value);
    },
    isError() {
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;
      let object = {};
      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    },
    value() {
      if (this.useDays) {
        this.durationInDays = Math.trunc(this.value / (24 * 60));
      } else {
        if (this.value) {
          this.setDayHourMinute();
        } else {
          this.selectedDay = 0;
          this.selectedHour = 0;
          this.selectedMinute = 0;
        }
      }
    },
    durationInDays(newValue) {
      this.$emit("input", newValue * 24 * 60);
    },
    soakDurationMax: {
      immediate: true,
      handler: function(newDuration) {
        if (newDuration) {
          if (!this.useDays) {
            this.setDayHourMinuteOptions();
          }
        }
      }
    }
  },
  methods: {
    setError(value) {
      const rt = Validator.genericValidation(value, this.filter);
      this.isError = rt.error;
      this.defaultErrorMsg = rt.msg;
      this.errorClass.error = this.isError || this.forceErrorMsg?.length > 0;
      let object = {};
      object[this.id] = this.errorClass.error;

      if (rt.notBlocked) {
        return;
      }
      this.$emit("error", object);
    },
    setCharAt(str, index, chr) {
      if (str === undefined) {
        return str;
      }
      if (index > str.length - 1) return str;
      return str.substring(0, index) + chr + str.substring(index + 1);
    },
    setDayHourMinute() {
      const time = parseInt(this.value);
      this.selectedDay = Math.trunc(time / 24 / 60);
      this.selectedHour = Math.trunc((time / 60) % 24);
      this.selectedMinute = time % 60;
    },
    setDuration() {
      let value = 0;
      value =
        this.selectedDay * 24 * 60 +
        this.selectedHour * 60 +
        this.selectedMinute;
      this.setError(value);
      this.$emit("input", value);
    },
    setDayHourMinuteOptions() {
      const time = this.soakDurationMax;
      const day = time / 24 / 60;

      let hour = 0;
      if (time >= 60 * 24) {
        hour = 23;
      } else {
        hour = time / 60;
      }

      let minute = 0;
      if (time >= 60) {
        minute = 59;
      } else {
        minute = time;
      }

      this.dayOption = [];
      let i = 0;
      while (i <= day) {
        if (i <= 1) {
          this.dayOption.push({ value: i, text: i + " Day" });
        } else {
          this.dayOption.push({ value: i, text: i + " Days" });
        }
        i++;
      }

      this.hourOption = [];
      i = 0;
      while (i <= hour) {
        if (i <= 1) {
          this.hourOption.push({ value: i, text: i + " Hour" });
        } else {
          this.hourOption.push({ value: i, text: i + " Hours" });
        }
        i++;
      }

      this.minuteOption = [];
      i = 0;
      while (i <= minute) {
        if (i <= 1) {
          this.minuteOption.push({ value: i, text: i + " Minute" });
        } else {
          this.minuteOption.push({ value: i, text: i + " Minutes" });
        }
        i++;
      }
    },
    focusChanged(e) {
      this.setError(this.value);
      this.$emit("binding", this.value);
    },
    onChange(value) {
      if (value === undefined || value === "") {
        value = null;
      } else if (this.useDays) {
        value = value * (24 * 60);
      } else {
        // value = parseInt(value);
        // this.$set(this.value, "value", value);
        // if (this.digits) {
        //   value = parseFloat(value);
        // }
      }
      this.$emit("input", value);
    },
    onKeydown(e) {
      if (e.key == "." || e.key == ",") {
        e.preventDefault();
      }
    }
  },
  mounted() {
    if (this.useDays) {
      if (this.value) {
        this.durationInDays = Math.trunc(this.value / (24 * 60));
      }
    } else {
      if (this.value) {
        this.setDayHourMinute();
      } else {
        this.selectedDay = 0;
        this.selectedHour = 0;
        this.selectedMinute = 0;
      }

      if (this.soakDurationMax) {
        this.setDayHourMinuteOptions();
      }
    }
  },
  beforeDestroy() {
    let object = {};
    object[this.id] = false;
    this.$emit("error", object);
  }
};
</script>

<style lang="scss" scoped>
.col-4 {
  padding: 0;
}

.label {
  color: #000;
}

.page form div > input:not([type="submit"]):not([type="button"]) {
  padding-right: 0.2rem;
  border-right-width: 0px;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}
</style>
