<template>
  <p :name="id">
    <b-input
      :disabled="disabled"
      :placeholder="placeholder"
      :value="value"
      :type="type"
      :inputmode="digits ? 'decimal' : ''"
      :autocomplete="hasAutocomplete"
      :formatter="formatValue"
      :class="errorClass"
      @input="onChange($event)"
      @blur="focusChanged"
    />

    <label
      v-if="isError || forceErrorMsg || forceErrorMsgNoBlock"
      class="error"
    >
      {{ errorMessage }}
    </label>
  </p>
</template>

<script>
import Validator from "@/utils/validator.js";

export default {
  name: "ExperimentalInput",
  props: [
    "value",
    "required",
    "disabled",
    "type",
    "autocomplete",
    "placeholder",
    "maxlength",
    "minlength",
    "min",
    "max",
    "digits",
    "refeshError",
    "otherValue",
    "errorMsg",
    "forceErrorMsg",
    "forceErrorMsgNoBlock",
    "initError",
    "help",
    "changed"
  ],
  data() {
    return {
      id: this.$options.name + "-" + (Math.random() * 100000).toFixed(0),
      hasAutocomplete: this.autocomplete,
      hasHelp: !!this.help,
      selected: null,
      tags: [],
      tag: "",
      tagClass: "tagometerUnder",
      position: 0,
      defaultErrorMsg: "",
      errorClass: { error: false },
      isError: false,
      toggleDataList: false,
      convertValue: 1
    };
  },
  computed: {
    valueLength() {
      if (this.value) {
        return this.value.length;
      }
      return null;
    },
    errorMessage() {
      var msg = this.defaultErrorMsg;
      if (this.forceErrorMsg) {
        msg = this.forceErrorMsg;
      } else if (this.errorMsg) {
        msg = this.errorMsg;
      } else if (this.forceErrorMsgNoBlock) {
        msg = this.forceErrorMsgNoBlock;
      }

      return msg;
    },
    sortOptions() {
      if (this.options) {
        return this.options.slice(0).sort(function(a, b) {
          return a.text.localeCompare(b.text, "en", { numeric: true });
        });
      } else {
        return [];
      }
    },
    filter() {
      return {
        type: this.type,
        required: this.required,
        min: this.min,
        max: this.max,
        digits: this.digits,
        maxlength: this.maxlength,
        minlength: this.minlength
      };
    }
  },
  watch: {
    refeshError() {
      this.refreshErrorMethod();
    },
    isError() {
      this.errorClass.error =
        this.isError ||
        this.forceErrorMsg?.length > 0 ||
        this.forceErrorMsgNoBlock;
      let object = {};
      object[this.id] = this.errorClass.error;
      this.$emit("error", object);
    },
    value() {
      if (this.type === "text") {
        if (this.changed) {
          this.changed("this.value");
        }
      }
    }
  },
  methods: {
    refreshErrorMethod() {
      this.setError(this.value);
    },
    setError(value) {
      const rt = Validator.genericValidation(value, this.filter);
      this.isError = rt.error;
      this.defaultErrorMsg = rt.msg;
      this.errorClass.error =
        this.isError ||
        this.forceErrorMsg?.length > 0 ||
        this.forceErrorMsgNoBlock;
      let object = {};
      object[this.id] = this.errorClass.error;
      if (rt.notBlocked || this.forceErrorMsgNoBlock) {
        return;
      }
      this.$emit("error", object);
    },
    setCharAt(str, index, chr) {
      if (str === undefined) {
        return str;
      }
      if (index > str.length - 1) return str;
      return str.substring(0, index) + chr + str.substring(index + 1);
    },
    handlephone(e) {
      let phone = this.phone.replace(/\D/g, "").replace(1, "");
      this.$emit("input", phone);
    },
    focusChanged(e) {
      this.setError(e.target.value);
    },
    onChange(value) {
      if (value === undefined || value === "" || value === null) {
        value = null;
      } else {
        if (this.digits) {
          value = parseFloat(value);
        }
      }
      this.$emit("input", value);
    },
    formatValue(value) {
      let x = value;
      if (this.digits) {
        x = x.trim();
        x = x.replace(/[^0-9]/g, "");
      }
      return x;
    }
  },
  beforeDestroy() {
    let object = {};
    object[this.id] = false;
    this.$emit("error", object);
  }
};
</script>
