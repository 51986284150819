<template>
  <div class="page-main">
    <h1>{{ $t("connexion.login") }}</h1>

    <p v-if="unsupportedBrowser">
      {{ $t("connexion.badBrowser") }}
    </p>

    <p v-if="needLocalStorage" class="topContentError">
      {{ $t("connexion.needLocalStorage") }}
    </p>

    <p v-if="needCookies" class="topContentError">
      {{ $t("connexion.needCookies") }}
    </p>

    <p v-if="needServiceWorker" class="topContentError">
      {{ $t("connexion.needServiceWorker") }}
    </p>

    <p v-if="!unsupportedBrowser" class="noAccount">
      {{ $t("connexion.noaccount") }}
      <router-link :to="{ name: 'connexionsignup' }">
        {{ $t("connexion.signup") }}
      </router-link>
    </p>

    <form
      v-if="!unsupportedBrowser"
      id="connection"
      class="connection"
      method="POST"
      @submit="validateLogin"
    >
      <fieldset>
        <GenericInput
          v-model.trim="inputs.username"
          :label="$t('fin.label')"
          :help="$t('fin.desc')"
          :refeshError="refeshError"
          :required="true"
          :finValidator="true"
          type="text"
          autocomplete="username"
          @error="checkError"
        />
        <br />
        <PasswordInput
          v-model.trim="inputs.password"
          :label="$t('fields.password')"
          :refeshError="refeshError"
          :resetPassword="true"
          :required="true"
          :displayPassword="true"
          autocomplete="current-password"
          @error="checkError"
        />
      </fieldset>
      <p class="loginError error" v-if="badPassword">
        {{ $t("connexion.bad") }}
      </p>
      <p class="serverReachError error" v-if="badInternet">
        {{ this.$store.getters.GET_CURRENT_ONLINE_ERROR }}
      </p>
      <button class="primaryAction" type="submit">
        {{ $t("connexion.login") }}
      </button>
    </form>
  </div>
</template>

<script>
import GenericInput from "@/components/GenericInput.vue";
import PasswordInput from "@/components/widgets/PasswordInput.vue";
import DB from "@/service/db";

export default {
  name: "connexion-login",
  data() {
    return {
      inputs: {
        username: "",
        password: ""
      },
      refeshError: false,
      needServiceWorker: false,
      needLocalStorage: false,
      needCookies: false,
      badPassword: false,
      badInternet: false
    };
  },
  components: {
    GenericInput,
    PasswordInput
  },
  computed: {
    unsupportedBrowser() {
      return (
        this.needCookies || this.needLocalStorage || this.needServiceWorker
      );
    }
  },
  methods: {
    checkError(value) {
      this.error = { ...this.error, ...value };
    },
    validateLogin: function(e) {
      e.preventDefault();
      const vuejs = this;
      const username = vuejs.inputs.username;
      const password = vuejs.inputs.password;
      if (username && password) {
        this.badPassword = false;
        this.badInternet = false;

        // .then(() => Jobel.checkInternetStatus())
        vuejs.$store
          .dispatch("logIn", {
            username,
            password
          })
          .then(function(b) {
            return Promise.resolve(
              vuejs.$router.push({ name: "modules" }).catch(error => {
                // ignore exception on navigation guard,
                // so we dont get treated by next catch
              })
            );
          })
          .catch(error => {
            if (error.errorType == "user") {
              vuejs.badPassword = true;
            } else {
              vuejs.$alertUserApiError(error);
            }
          });
      }
    },
    runBrowserChecks() {
      const vm = this;

      const checks = [
        function checkCookies() {
          if (!navigator?.cookieEnabled) {
            document.cookie = "cookietest=1";
            vm.needCookies = document.cookie.indexOf("cookietest=") === -1;
            document.cookie =
              "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
          }
        },

        function checkServiceWorker() {
          if (!("serviceWorker" in navigator)) {
            vm.needServiceWorker = true;
          }
        },

        function checkClientAuthenticatable() {
          DB.getItem("isClientAuthenticatable")
            .then(isClientAuthenticatable => {
              vm.needLocalStorage = isClientAuthenticatable === false;
            })
            .catch(() => {
              vm.needLocalStorage = true;
            });
        }
      ];

      checks.forEach(check => check());
    }
  },
  mounted() {
    this.runBrowserChecks();
  }
};
</script>
